body, *, input, input{
  font-family: 'Livvic', sans-serif !important;
  font-size: 14px;
}
body{
  color: #454343;
}
h1, h2, h3, h4, h5, h6 {
  color: #2f2f2f;
}
h3, h4, h5, h6{
  color: #313131;

}
.swal-footer {
    text-align: center !important;
}
.swal-modal {
  padding: 15px 25px;
  box-shadow:5px 5px 0 rgb(0 0 0 / 2%);
}
.swal-text {

  color: #313131 !important;
    text-align: center !important ;
  color: #2f2f2f !important;
  font-weight: 600 !important;
  font-size: 25px;
}
/*for chrome*/
.custableresponsive::-webkit-scrollbar-track-piece, ::-webkit-scrollbar-track-piece, .table-responsive::-webkit-scrollbar-track-piece, .product_category_handle::-webkit-scrollbar-track-piece, .scroll_checkout_pages::-webkit-scrollbar-track-piece  {
    background-color: #f9f9f9;
}
/* width */
.custableresponsive::-webkit-scrollbar, ::-webkit-scrollbar, .table-responsive::-webkit-scrollbar, .product_category_handle::-webkit-scrollbar, .scroll_checkout_pages::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}
/* Handle */
.custableresponsive::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb, .product_category_handle::-webkit-scrollbar-thumb, .scroll_checkout_pages::-webkit-scrollbar-thumb {
  background: #b8b8b8 !important;
  border-radius: 2px;
}
/* Handle on hover */
.custableresponsive::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover, .product_category_handle::-webkit-scrollbar-thumb:hover, .scroll_checkout_pages::-webkit-scrollbar-thumb:hover {
  background: #8a8383 !important;
}

/*for firebox*/
.custableresponsive, body, html, .table-responsive, .product_category_handle, .cart_height_set, .scroll_checkout_pages, .scroll_order_pages, .custableresponsive {
    scrollbar-color: #b8b8b8 #f9f9f9 !important;
    scrollbar-width: thin !important;
}
.mrMain{
  margin-left: 0rem;
    color: #131740;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
     display: inline-block !important;
    border-radius: 45px;
    padding: 5px 6px;
    background: #fff;
    padding-left: 11px;
    cursor: pointer;
}
.mobMenu .mrMain {
    display: block !important;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15px;
}

.dropMenuIn .mrImg {

  margin-right: 5px;
}
.dropMenuIn .mrMain {
  background: #f9f9f9;
    padding-left: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 13px;
      margin-bottom: 10px;
      display: none !important;
}
.mrMain {
  background: #9c9c9c25 !important;
}
.bodydarkMode .mrMain {
  color: #fff;
}
.mrImg {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50px;
  margin-left: 5px;
}
.img-fluid{
  max-width: 100%;
}
.sortIcon {
  position: relative;
}
.custableresponsive {
  overflow: hidden ;
  height: 396px;
}
.custableresponsive table {
    overflow-x: hidden;
    margin: 0px !important;
    width: 100%;
}
.custableresponsive th{
  padding:10px 10px !important;
  font-weight: 700 !important;
  color: #131740;
}
.darkMode .custableresponsive th, .darkModeExchange .custableresponsive th {
  color: #fff;
}
.custableresponsive th>div {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700 !important;
  padding-bottom: 0rem !important;
  padding: 0rem !important;
}
.sortIcon{
  display: grid;
}
.custableresponsive tr {
}
.custableresponsive tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 0rem 10px !important;
    padding-bottom: 0rem !important;
}
.custableresponsive tr td:last-child, .custableresponsive tr th:last-child {
  text-align: left;
}
.custableresponsive tr th:last-child>div{
  justify-content: flex-start;
}
.mt-15 {
  margin-top: 10px;
}
footer .container {
    max-width: 1300px !important;
}
.custableresponsive_height {
    height: 443px;
}
 table tr td {
    border-color:  #e1e8f6 !important;
}
.w_40 {
  width: 40%;
}
.w_60 {
  width: 60%;
}
.inputHandle>div h3 {
  background-color: #fff !important;
  color: #301e1e !important;
  height: 45px;
  line-height: 27px;
  border: 1px solid #dbf0ff  ;
  margin: 0px !important;

}
.inputHandle>div h3+inPut+h3 {
  border-left: 0px;
}
.inputHandle>div h3+inPut {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-left: 0px !important;
  height: 45px;
  background: #f3faff;
  margin: 0px !important;
}
.widthpad {
  width: 90% !important;
  padding: 10px 0px;
}
.widthpad>div {
  border-radius: 4px;
}
.cusSelect select {
    border: 0px;
    margin-left: 5px;
    background-color: #ccddff;
    padding: 7px 10px !important;
    border-radius: 6px;
    padding-right: 8px !important;
}
.headStyle {
    border-radius: 0.3rem;
  background-color: #e1e8f6;
}
.headStyle th {
}
.darkMode .custableresponsive  thead {
    background-color: #29273a !important;
}
.headStyle ~ tbody td {
    padding: 10px 6px !important;
    border-color:  #e1e8f6 !important;
    border-top: 1px solid ;

}
html body .darkMode .custableresponsive  td{
  border-color:#8e8e8e !important;
  color: #fff;
}
.textmobhandle {
  text-align: initial;
  font-size: 14px;
}
.navRight button.btns {
  margin-right: 14px !important;
}
.darkLight {
  margin: 0px 0px !important;
}
.ligtMode.cusHero, .ligtMode.signPage {
  background: #fff !important;
  position: relative;
}
/* Menubar_l_menu__zCdDf */
html body .mobMenuLight {
  background: #fff !important;
}

.darkMode.cusHero, .darkMode.signPage {
  background: #1b1927 !important;
  position: relative;
}
html body .mobMenuDark {
  background: #1b1927 !important;
}
html body .mobMenu>*{
  position: relative;
}
.darkMode.cusHero::before, .darkMode.signPage::before, .mobMenuDark:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 0100%;
    opacity: 0.2;
    background: url(../public/hero_dark_bg.png);
    background-size: cover;
    background-position: center;
    left: 0px;
}
.cusHero>*, .ligtMode.signPage>*, .darkMode.signPage>* {
  position: relative;
}
.cusHero h1 {
  margin-top: 0px !important;
}
.cusHero p.pHighlight{
}
.cusHero p span {
    background-color: #ffaa02 !important;
    min-width: 40px;
}
button.btn, button.btns, a.btns, a.btn, .inputHandle>button {
  color: #fff !important;
  padding: 8px 25px;
  border-radius: 8px;
  font-size: 16px !important;
  background: #313131 !important;
}
button{
  
  text-transform: capitalize;
}
.btn+.btns {
  margin-left: 15px !important;
}
button.btns, a.btns {
  background: #6b6b6b !important;
}
.btn:hover {
  background: #3f3d3d !important;
}
.wIcon {
  background-color: #3bb93d !important;
}
.h1Every{
    font-weight: 700 !important;
    font-size: 36px !important;
}
.ligtMode  .h1Every{
    color: #131740 !important;
}
.smallSlider.ligtMode h2  {
    color: #454256 !important;
}
.secured.ligtMode {
  background: #f9faff !important;
}
.snsecure img.snsecureImg {
      background-color: #7110ff87 !important;
}
.snsecure:nth-child(1) img.snsecureImg {
      background-color: #ffc9c2 !important;
}
.snsecure:nth-child(3) img.snsecureImg {
      background-color: #c7e5ff !important;
}
.cusHero {
  padding-bottom: 70px;
}
.footerLogo img{
  width: 170px;
}
.SocialLinkFooter {
  margin-top: 10px;
}
.SocialLinkFooter a {
    height: 30px;
    width: 30px;
    background-color: #dbe1ff;
    color: #484848;
    line-height: 28px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50px;
    text-align: center;
    margin-top: 10px;
}
footer h3 {
  font-size: 20px;
  font-weight: 600;
}
.fLink, .SocialLinkFooter {
  margin-bottom: 20px;
}
.fLink a {
  display: block;
  font-size: 14px;
  color: #626262;
  margin-bottom: 8px;
}
.smallSlider.darkMode {
  background: #1b1927;
}
footer.darkMode h3 {
  color: #fff;
}
footer.darkMode .fLink a {
  color: #fff;
}


.darkMode .conWrapper::before{
    opacity: 0.2;
    background: url(../public/contactbgDark.jpg);
    background: url(../public/hero_dark_bg.png);
}
html body  .darkMode .headStyle ~ tbody td, html body .darkMode .custableresponsive tbody td {
  color: #fff;
  border-color: #e1e8f64d !important;
}
.relative {
  position: relative;
}
.buysellOrder td {
  cursor: pointer;
}
.Form.ligtMode .inPut {
  background-color: #0a0a0a17;
}
.darkMode [class*='Withdraw_withdraw_d__'] form input, .darkMode .inputdark, .darkMode .cusSelect select, .stakePage.darkMode input, .stakePage.darkMode select, .buysellForm.darkMode .inPut, .buysellForm.darkMode .perBox button, .darkMode.signPage input, .darkMode.signPage select{
  background-color: #2e2c3b !important;
  border: 0px !important;
  color: #fff !important;
}
.darkMode [class*='Withdraw_withdraw_d__'] form input {
  border-color: #2e2c3b !important;
  border: 01px solid  #2e2c3b!important;
    background-color: #232135 !important
}

.darkMode [class*='Withdraw_withdraw_d__'] .inputHandle>div h3 {
    background-color: #ffd5b93d !important;
    color: #fff !important;
}
.buysellForm.darkMode .inPut input {
  color: #fff !important;

}

.lightMode.signPage input, .lightMode.signPage select{
  background-color: #fff !important;
  border: 0px;
  color: #454545 !important;
}
.darkMode.signPage input:-webkit-autofill, .darkMode.signPage input:-webkit-autofill:focus {

    box-shadow: 0 0 0 50px #a7a1a1 inset  !important;
}
.darkMode.signPage input::placeholder{
  color: #fff !important;
}
.buysellForm.darkMode .perBox button {
  background-color:#302d3f;
}
.stakePage.darkMode .inPut input .buysellForm.darkMode .inPut input, .buysellForm.darkMode .inPut h4, .buysellForm.darkMode .perBox button, .buysellForm.darkMode .inPut span {
  color: #fff;
}
.buysellForm.darkMode .inPut h4 span{
      color: #c69d02;
}
.height100vh .cusHeroIn {
  display: flex!important;
      justify-content: space-between;
}

.darkMode select{
  background-image: url("../public/dowwhite.png")  !important;
  background-position: 97% 50%  !important;
  appearance: none !important;
  background-repeat: no-repeat;
  padding-left: 10px;
}
.withIcon {
  position: relative;
}
.withIcon button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.withIcon button.send-otp-button {
      top: 8px;
}
.darkMode .sgn_bottom{
      background-color: #ffffff21 !important;
}
.darkMode .sgn_bottom a {
      color: #fff !important;
}
[alt="nodata"]  {
  height: 160px !important;
  border-radius: 0px !important;
}
.tabbtns  button.btn {
  background: #6b6b6b  !important;
  box-shadow: none !important;
  padding: 10px 25px !important;
}
.tabbtns  button.btn {
}
.tabbtns  button.btn[class*="History_active"] { 
  background: #3f3d3d  !important ;
  opacity: 1;
}
.darkMode [class*="PaginationBox"] button {
      background-color: #ffffff0f;
}
 [class*="PaginationBox"] button {
          color: #8e8e8e !important;
}
.ligtMode.navbarMain {
  background-color: #f5f5f5;
}
.cusHero .ligtMode.navbarMain {
  background-color: transparent;
}
[class*="Navbar_box__"] {
}
body [class*="Navbar_box__"] {
  border: 1px solid #ececec !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
  align-items: stretch;
  justify-content: center;
  top: 3rem !important;
}
.ligtMode [class*="Navbar_box__"] {
      background-color: #fff !important;
    align-items: stretch;
          justify-content: center;
          border-radius: 15px !important;
}

[class*="Navbar_box__"] a, [class*="Navbar_box__"] button{
  text-align: left;
  padding: 10px 18px;
  margin: 0px !important;
    color: #131740 !important;
}
.dropMenu {
  display: inline-block !important;
}
.dropMenu a i, .dropMenu button i [class*="Navbar_box__"] a, [class*="Navbar_box__"] button, [class*="Navbar_box__"] svg path{
  color: #323232 !important;
  stroke: #323232 !important;
}
[class*="Navbar_box__"] a:hover, [class*="Navbar_box__"] button:hover{
      background: #ffffff0a !important;
      color: #131740 !important;
}
body [class*="Navbar_box__"] a:hover, body [class*="Navbar_box__"] button:hover{
      color: #ffaa02  !important;
}
.dropMenuIn {
  padding: 15px 0px !important;
}
.darkMode .dropMenu a {
  font-size: 14px;
  color: #fff !important;
}
.dropMenu a i, .dropMenu button i {
    width: 22px;
    display: inline-block;
    text-align: center;
}
.stakeMain{
  position: relative;
  overflow: hidden ;
}
.ligtMode .stakeMain {
}
.stakeMain:before{
}
.stakeMain>*{
  position: relative;
}
.stakeMain h1, .stakeMain h2, .stakeMain h3, .stakeMain p{
  color: #3a3838 !important;
}

.stakeMain h1 {
  font-weight: 700 !important;
  font-size: 28px !important;
  margin-bottom: 5px !important;
}
.stakeMain h2{
  color: #4e4d4d !important;
  font-size: 24px !important;
  margin-top: 10px !important;
}
.stakeMain h3{
  color: #4e4d4d !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
}
[class*="Stack_impn_"] p:before{
  background-color: #3a3838 !important;
}
.pl-2 {
  padding-left: 5px;
  font-weight: 800;
}

.white {
  color: #fdc20d;
 }

.darkMode [class*='ChangePassword_password_'] {
    background-color: #2e2c3b !important ;
  }
.snsecure {
  padding: 0px 15px !important;
}
.bannerIn {
  padding: 35px 15px;
  border-radius: 15px;
  margin-top: 35px;
  color: #fff;
  margin-bottom: 15px;
}
.bannerIn, .bannerIn>* {
  position: relative;
  overflow: hidden;
}
.bannerIn a.custombtn {
  color: #fff;
  border-radius: 8px;
  padding: 8px  25px;
  font-size: 14px;
  background-color: #00caa9  !important;
}
.bannerIn:before {
    background-image: url(../public/balanceBanner.png);
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.balanceBanner2 .bannerIn:before {
    background-image: url(../public/balanceBanner2.png);
}
.tableDesign h2{
  font-size: 20px;
  font-weight: 600;
}
.tableDesign h3, 
.tableDesign thead th, .tableDesign thead th div {
  color: #6c6c6c  !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.tableDesign .custableresponsive {
  overflow: hidden;
  height: auto;
}
.tableDesign table {
  background: transparent !important;
}
.tableDesign thead{
  background: transparent !important;
}
.tableDesign .custableresponsive tr td {
  color: #848484 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
      font-size: 13px;
}
.darkMode .tableDesign .custableresponsive tr td {
  color: #c5c5c5 !important;
}
.tableDesign td.flex {
  font-weight: 600 !important;
}
.tableDesign td button{
  border-radius: 8px !important;
  background-color: #00caa9 !important;
  margin: 2px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0.3rem 1rem; 
  border: 0px;
}
.tableDesign td button:first-child {
  background-color: #4a67f0 !important;
}
.tableDesign tr{

}
.tableDesign tr:hover td{
  border: 0px;
}
.noteHere {
  font-size: 16px;
}
.tbalehead {
  padding: 0px !important;
}
.darkMode .noteHere {
  color: #fff !important;
}
.darkMode .tableDesign .custableresponsive thead {
  background: transparent !important;
}
.darkMode .tableDesign h2, 
.darkMode .tableDesign .custableresponsive th div {
  color: #fff !important;
}
.stBox {
    background: #7272721a;
    border-radius: 14px;
    padding: 15px;
    text-align: center;
    min-height: 136px;
    margin-bottom: 15px;
    justify-content: space-around;
}
.stBox>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.stBox p {
  padding: 0px !important;
  margin-top: 0px !important ;
}

.darkMode .stBox p, .darkMode .sponerHere p {
  color: #fff !important;
}
.stBox p:before{
  opacity: 0;
}
.stBox h4 {
  margin: 0px !important;
    font-size: 21px !important;
    font-weight: 600 !important;
}
.btnbox {
  background-color: #4a67f0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 250px !important;
  padding: 0.5rem 1.2rem;
  color: #fff;
  display: inline-block;
  margin-top: 5px ;
}
.btnbox:hover {
  color: #fff;
}
.inviteFull {
  padding: 20px 20px !important;
}
.inviteFull h4 {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
}
.stakeMain h2.inviteBox {
  font-size: 14px !important;
  color: #514f4f;
  padding: 0.5rem 1rem;
  font-weight: 600 !important;
  background: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 7px 8px !important;
  border-radius: 45px !important;
}
.stakeMain h2.inviteBox button {
    height: 25px;
    width: 25px;
    background-color: #4a67f0 !important;
    border-radius: 45px;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
}
.sponerHere p{
  margin-bottom: 3px !important;
  font-size: 14px !important;
  font-weight: 600;
}
.snPlan {
    background: #f5f5f5 !important;
    position: relative;
    overflow: hidden;
}
.snPlan:before {
    background-image: url(../public/bg-convert.png);
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
}
.snPlan>*{
  position: relative;
}
.snPlan:nth-child(even) {
  background-color: #fff4e5 !important;
}
.snPlan p {
  margin-bottom: 5px !important ;
}
.snPlan h1 {
  font-weight: 600 !important;
}
.outerBorder {
  border: 1px solid #8393b138 !important;
  border-radius: 4px ;
      background: #ffffff0a;
}
[class*='Coininfo_coininfo_'] ul li a {
  word-break: break-all;
}


.darkModeInfoPage {
    background-color: #1e1c29;
}
.darkModeInfoPage .sn_page_pad h1, .darkModeInfoPage .sn_page_pad h2, .darkModeInfoPage .sn_page_pad h3, .darkModeInfoPage .sn_page_pad h4, .darkModeInfoPage .sn_page_pad h5, .darkModeInfoPage .sn_page_pad h6 {
  color: #fff;
}
.darkModeInfoPage hr:not([size]) {
    height: 1px;
    background-color: #ffffff3b;
}
.darkModeInfoPage .sn_page_pad p, .darkModeInfoPage .sn_page_pad li,  .darkModeInfoPage .sn_page_pad i {
  color: #c5c5c5;
}
.sn_page_pad h3 {
    margin-top: 15px;
    font-size: 16px;
}
.qrcodeNote {
  width: 60% !important;
}
.qrcodePOP {
  width: 40% !important;
}
.ExchangePage .custableresponsive tr td, .custableresponsive th {
  padding-left:6px !important;
  padding-right:6px !important;
}
.ExchangePage .custableresponsive tr td {
  padding-top: 4px !important ;
}
html body .custableresponsive.heightAutoTable {
  height: auto !important;
}

.darkMode .bgin {
    background-color: #2e2c3b !important;
}

.bannerIn h4 {
  color: #fff;
}
@media (min-width: 1200px) {
  .height100vh {
      height: 87vh;
      display: flex;
      align-items: center;
  }
  .height100vh .navbarMain  {
    position: absolute;
    width: 100%;
    top: 0px;
  }
  .height100vh .cusHeroIn {
    display: flex!important;
    justify-content: space-between;
  }
}
@media (max-width: 1200px) {

  .dropMenu .dropMenuIn>.mrMain {
    display: block !important;
  }
  .dropMenu>.mrMain .mr {
    display: none !important;
  } 
  .dropMenu>.mrMain {
    padding-left: 6px !important;
  }
  .mrMain>img {
    margin-left: 0px !important;
  }
}
@media (max-width: 1200px) {

  .height100vh .cusHeroIn {
    display: flex!important;
    justify-content: space-between;
  }
  .height100vh .navbarMain  {
    position: relative;
    width: 100%;
    top: 0px;
  }
  .height100vh {
    height: auto;
      display: flex;
      align-items: center;
  }
}
@media (min-width: 767px) {

  .tableDesign tbody tr:hover {
    background: #d0ebffa8 !important;
    box-shadow: 0px 1px 18px 0px rgb(85 184 255 / 13%);
  }
  .darkMode .tableDesign tbody tr:hover {
    background: #191725a8 !important;
    box-shadow: 0px 1px 18px 0px rgb(28 28 29 / 62%);
  }
 
  .tableDesign .custableresponsive tr td, .tableDesign th {
    padding-left: 10px !important;
  }
  .height100vh .cusHeroIn{
    margin-top: 25px;
  }

  /* .historyTable tbody tr:hover {
    background: #d0ebffa8 !important;
    box-shadow: 0px 1px 18px 0px rgb(85 184 255 / 13%);
  }
  .darkMode .historyTable tbody tr:hover {
    background: #191725a8 !important;
    box-shadow: 0px 1px 18px 0px rgb(28 28 29 / 62%);
  }
  */
  .historyTable tr td, .historyTable th {
    padding-left: 10px !important;
}

}
@media (max-width: 767px) {


  .cusHero h1 {
    line-height: 41px !important;
  }
  .cusHero p.pHighlight {
    margin-bottom: 5px;
  }
  .h1Every {
    font-size: 22px !important;
  }
  .cusHero p span {
    display: none;
  }
  .heroSlide {
    margin-top: 40px !important;
  }
  .cusHero {
      padding-bottom: 40px;
  }
  .secureMain {
    grid-template-rows: 0fr 0fr 1fr 0fr !important  ;
  }
  .line {
    display: none;
  }
  .secureMain {
    margin-bottom: 0px !important;
  }
  .snsecureImg ~ h3 {
    font-size: 18px !important;
  }
  .tbalehead .flex-between {
    flex-direction: column;
    padding: 0px 15px;
    align-items: flex-start;
  }
  [class*='Balance_widthdrawBox__']  .flex-between, [class*='Balance__nav__'], [class*='Deposit_address_'] {
        flex-direction: initial !important;
    align-items: initial !important;
  }
  [class*='Balance_widthdrawBox__']  .flex-between{
    margin-bottom: 15px;
  }
  [class*='Balance_widthdrawBox__']  .flex-between input, [class*='Balance_widthdrawBox__']  .flex-between button {
    margin: 0px;
  }  
  [class*='Balance_note_'] {
    padding: 0px 15px;
  }
  .bannerIn {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .tableDesign .custableresponsive {
    height: 380px !important;
    height: auto !important;
    overflow-x: auto !important;
  }
  .stakeMain h2.inviteBox {
    border-radius: 10px !important;
    display: block;
    text-align: center;
  }
  .stakeMain h2.inviteBox button{
    margin: auto !important;
    display: block;
  }
  .tableDesign .custableresponsive.outerBorder {
    height: auto !important;
    margin-bottom: 15px;
  }

  .qrcodeNote {
    width: 100% !important;
  }
  .qrcodePOP {
    width: 100% !important;
  }
  .buysellbg {
    display: none;
  }
  html body [class*='History_his_d__'] [class*='History_slider__'] {
    width: 100% !important;
    padding: 0px !important;
  }
  [class*='History_slider__']>div {
    transform: inherit !important;
  }
  .tabbtns button.btn {
    font-size: 12px !important;
        padding: 9px 13px !important;
  }
  .imgmobhide {
    display: none;
  }
  .mobile100 {
    width: 100% !important;
  }
  html body [class*='History_his_']  [class*='History_his__']  [class*='History_slider__'] {
    width: 100% !important;
  }
  .dropMenuIn {
    right: -80px !important;
  }
}
@media (max-width: 550px) {
  .buysellbs {
    align-items: inherit !important;
  }

}
.desktopScroolTable {
  overflow-x: scroll !important;
}
.dkMFNX svg {
  display: none !important;
}
.darkModeText{color:#ffffff !important}

:root {
    --cubic: cubic-bezier(.305,.045,.355,1);
    --transition-btn: .4s;
    --transition-active: .1s;
    --transition-load: .8s var(--cubic);
    --font-family-main: "Livvic",Helvetica,Arial,sans-serif;
    --bg-body-rgb: 35 38 61;
    --bg-body: rgb(var(--bg-body-rgb));
    --bg-primary-rgb: 53 58 88;
    --bg-primary: rgb(var(--bg-primary-rgb));
    --bg-secondary-rgb: 41 45 69;
    --bg-secondary: rgb(var(--bg-secondary-rgb));
    --bg-opacity: .85;
    --color-primary-rgb: 245 245 245;
    --color-primary: rgb(var(--color-primary-rgb));
    --color-secondary-rgb: 107 114 156;
    --color-secondary: rgb(var(--color-secondary-rgb));
    --primary-rgb: 222 62 141;
    --primary: rgb(var(--primary-rgb));
    --up: #53ba00;
    --down: #be0707;
    --perspective: 400px;
    --deg-x: 20;
    --shadow-primary: 0 0 30px rgba(0,0,0,.1),0 0 0 1px rgba(0,0,0,.1);
    --shadow-secondary: 0 0 25px #2a2f4a inset
}
.padSec {
  padding: 70px 0px;
}
.padSecimporant {

  padding: 60px 0px !important;
}
.padBottom {
  padding-bottom: 0px;
}

html body .navbarMain  {
    height: 68px;
}
.navbarMain .container {
  position: relative;
}

.navbarMain .container>div{
  z-index: 1;
}
.navbarMain .container>div.bg {
    perspective: calc(var(--perspective)/2.5);
}
.navbarMain .container>div.bg {
    inset: calc(var(--radius)*-1) 0 0;
    perspective: var(--perspective);
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 85px;
    left: 0px;
    right: 0px;
    margin: auto;
    --radius:  25px;
}
.navbarMain .container>div.bg:before {
  content: '';
    background: #fff;
    border-radius: 0 0 var(--radius) var(--radius);

    inset: -0px 0 0;
    position: absolute;
    transform: rotateX(calc(1deg*var(--deg-x)*-1));
    transform-origin: bottom center;
    z-index: 5;
    height: 100%;
    width: 100%;
    display: block !important;
}

.navbarMain .navlinks a {
  color: #232323 !important;
  font-size: 16px;
  font-weight: 500;
}

.navbarMain .navlinks a[class*='Navbar_active_nav_link__'] {
  color: #ffaa02 !important;
}
.navbarMain .navlinks a.wIcon {
  color: #fff;
    padding: 0.6rem;
  font-size: 16px;
}
.darkLightBtn {
  position: fixed;
  right: 15px;
  top: 0px;
  bottom: 0px;
  height: 80px;
  margin: auto !important;
  background-color: #35353563;
  padding: 5px 5px;
  border-radius: 50px;
  z-index: 10;
}

.darkLightBtn i {
    color: #fff;
    padding: 10px 0px;
    background-color: transparent;
    width: 30px;
    border-radius: 35px;
    height: 30px;
}
.bodylightMode .darkLightBtn i.fa-lightbulb {
  background-color: #ffffff30;
}
.bodydarkMode .darkLightBtn i.fa-moon {
  background-color: #ffffff30;
}
.darkLightBtn i:first-child {
  margin-bottom: 7px;
}
.fa-regular, .fa-solid, .far, .fas {
    font-family: "Font Awesome 6 Free" !important;
}
.bgColor {
  background: #f7f5ff;
}
.h100vh {
    height: 100vh;
    align-items: center;
    display: flex;
    padding-bottom: 0px;
}
.sliderHero .pHighlight {
  font-size: 16px;
  font-weight: 500;
}
.sliderHero h1 {
    font-weight: 700;
    font-size: 43px;
    width: 60%;
    margin: auto;
}
.navabsolute .navbarMain {
    position: absolute;
}

section {
}
.slideMulti {
    margin-top: 50px;
}
.snBoxImage {
    width: 98px;
}
.snBox h3 {
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 3px;
    color: #303030;
    margin-bottom: 0px;
}
.snBox h4 {
    text-transform: uppercase;
    font-weight: 800;
    color: #313131;
    font-size: 29px;
}
section .btnsHere a {
  font-weight: 500;
}
section .btnsHere i {
  margin-right: 5px;
}

.snBox .snBoxImage, .snBox h4, .snBox .snBoxImage{
  transition: all 0.5s ease-in-out;
    transform: translateY(0%);
}
.snBox {
  transition: all 0.5s ease-in-out;
  position: relative;
}

.snBox>* {
  position: relative;
  z-index: 2;
}
.snBox>div.bgbox {
  background: radial-gradient(rgb(#e7e2fe) 0,transparent 90%);
  border-radius: 50%;
  filter: blur(100px);
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: var(--transition);
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: #ffb5e88f;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

.snBox:hover>div.bgbox {
  opacity: 1;
}

.slideMulti>div:nth-child(2) .snBox>div.bgbox {
  background: #d7deff;
}
.slideMulti>div:nth-child(3) .snBox>div.bgbox {
  background: #ffd1b58f;
}
.titleh2 {
    font-weight: 700;
    font-size: 34px;
}
.snBoxlisting  {
    background: #ffffff50;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    padding: 12px 15px;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;
    box-shadow: 3px 3px 10px rgb(119 119 119 / 6%);
}
.rigthbigcon {
    align-items: flex-start;
    flex-grow: 1;
}
.rightCon{
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    text-align: right;
}
.snBoxlisting .leftCoin img {
  width: 54px;
}
.snBoxlisting .rightContent * {
  margin-bottom: 0px;
}
.snBoxlisting .rightContent .leftCon {
  text-transform: uppercase;
}
.snBoxlisting .rightContent .leftCon h5  {
  font-weight: 600;
}
.snBoxlisting .rightContent .leftCon p{
  color: #959595;
  font-weight: 600;
}
.rightCon p {
  font-size: 12px;
}
.rightCon p.percentage {
  margin-top: 5px;
  font-weight: 500;
}
.greentext {
  color:  #0bc87a;
}
.redtext {
  color:  #ff6969;
}
.listingMulti {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}
.newListing {
  position: relative;
}
.newListing:before {
    content: '';
    border-radius: 50%;
    filter: blur(100px);
    opacity: 1;
    pointer-events: none;
    position: absolute;
    height: 200px;
    width: 350px;
    background: #e1dafd;
    transition: all 0.5s ease-in-out;
    top: 110px;
    left: 10%;
}
.newListing:after {
    content: '';
    border-radius: 50%;
    filter: blur(100px);
    opacity: 1;
    pointer-events: none;
    position: absolute;
    height: 200px;
    width: 350px;
    background: #ced8ff;
    transition: all 0.5s ease-in-out;
    top: 50px;
    right: 10%;
}
.newListing .container {
  position: relative;
  z-index: 1;
}
.banners {
  width: 50%;
  margin: auto;
  border-radius: 8px;
  border: 3px solid #adadb0;
  overflow: hidden;
}
.banners img {
}
.Adds,
.Adds * {
  position: relative;
}

.Adds:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background: #faf9ff;
}

.featureSec {
  background: #faf9ff;
}
.featureSec h4 {
    font-weight: 700;

}
.featureImg img {
    background: #e3e7ff;
    border-radius: 65px;
    width: 65px;
    margin-bottom: 10px;
    padding: 3px;
  animation: Secured_border 10s linear infinite alternate forwards;
  background-color: #e3ddfc;
  padding: 1rem;
  position: relative;
  width: 120px;
  z-index: 2;
}

@keyframes Secured_border {
    0%,to {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%
    }

    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%
    }

    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%
    }

    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%
    }

    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%
    }

    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%
    }

    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%
    }
}
.row>div:nth-child(1) .snfeatue .featureImg img  {
    background-color: #ffc9c2!important;
}

.row>div:nth-child(2) .snfeatue .featureImg img {
    background-color: #7110ff87!important;
}

.row>div:nth-child(3) .snfeatue .featureImg img {
    background-color: #c7e5ff!important;
}
.cryptoIn {
  background: #fff;
  border-radius: 8px;
  padding: 25px 25px;
}
.cryptoIn  .tabbtn  {
  border: 0px;
  background: transparent;
  color: #646464;
  font-weight: 700;
  margin-right: 20px;
  font-size: 20px;
}
.cryptoIn .nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
.cryptoIn  .tabbtn.active {
  color: #212529;
}
.latestdesigntable>:not(:last-child)>:last-child>* {
    border-bottom-color: transparent;
}
.table>:not(caption)>*>* {
  border-color: #f9f9f9 !important;
}
.latestdesigntable th {
 color: #454343;
 font-weight: 500;
 padding: 10px 0px;
}
.latestdesigntable tr td {
 padding: 10px 0px;
 color: #363535;
 font-weight: 500;
}
.latestdesigntable tr td  a{
 color: #363535;
}
.latestdesigntable tr td  a:hover {
 color: #ffaa02 !important;;
}
.latestdesigntable tr td .icon{
  height: 42px;
}

.latestdesigntable tr td .icon+b+span {
  margin-left: 5px;
  text-transform: uppercase;
  color: #807f7f;
}
.btnslabel .snbtnlabel>div {
  background: #e3deff;
  border-radius: 8px;
  padding: 10px 20px;
}
.btnslabel .snbtnlabel>div i {
    background: #c0b5fd;
    height: 30px;
    width: 30px;
    border-radius: 35px;
    text-align: center;
    line-height: 30px;
    color: #695bb6;
}

.btnslabel .snbtnlabel>div>div i+span {
    color: #363535;
    font-weight: 600;
  text-transform: capitalize;
}

.btnslabel>div:nth-child(2) .snbtnlabel>div {
    background: #ffe2c0;
}
.btnslabel>div:nth-child(2) .btnhere a, .btnslabel>div:nth-child(2) .snbtnlabel>div>div i {
  background: #f3bb79;
  color: #b46a12;
}
.btnslabel>div:nth-child(3) .snbtnlabel>div {
  background: #ffd9f9;
}
.btnslabel>div:nth-child(3) .btnhere a, .btnslabel>div:nth-child(3) .snbtnlabel>div>div i {
  background: #dc97d0;
  color: #913381;
}
.btnslabel>div:nth-child(4) .snbtnlabel>div {
  background: #9eedf9;
}
.btnslabel>div:nth-child(4) .btnhere a, .btnslabel>div:nth-child(4) .snbtnlabel>div>div i {
  background: #d2f9ff;
  color: #45b0c0;
}
.btnslabel .snbtnlabel .btnhere a {
  background: #c0b5fd;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 12px;
  color: #3f3f3f !important;
  font-weight: 500;
  display: flex;
}
.Partners {
    background: #f9f7ff;
}
.Partners .swiper-wrapper {
  align-items: center;
}
.Partners .swiper-wrapper img {
  margin-top: 30px;
  height: 300px;
}
.Partners .swiper-wrapper img.w50 {
 width: 45%;
 display: inline-block;
 margin-right: 20px;
}
.Partners .swiper-wrapper .displayInlineBlock {
 width: 45%;
 display: inline-block;
 margin-left: 20px;
}
.Partners .swiper-wrapper .displayInlineBlock p{
 text-align: justify;
}
html body footer {
  background: #fff !important;
  border-top: 1px solid #f1f1f1;
}
html body footer+div {
    background-color: #f7f7f7 !important;
}
.padtop0Sec {
  padding-bottom: 70px;
}
.marketlink a {
    background: #cfe6ff;
    padding: 10px 15px;
    border-radius: 8px;
        color: #313131;
}
.ExchangePage {
  background: #f7f5ff !important;
}
.ExchangePage .navbarMain .container>div.bg:before {
  transform: inherit !important;
}
.ExchangePage .navbarMain .container>div.bg {
  --radius: 0px;
  height: 75px;
}
.ExchangePage .latesthandle {
  background-color: #fefdff !important;
  border: 0px !important;
  border-radius: 4px !important;
  overflow: hidden;
}
 
.latesthandle input {
  background: #e0ecf3 !important;
  color: #3f3d3d !important;
  font-weight: 500;
  font-size: 12px;
  border-radius: 8px !important; 
  padding-left: 15px !important;
  height: 35px !important;
}
.latesthandle input::placeholder {
  color: #3f3d3d !important;
}
.custableresponsive th,  .custableresponsive th,  .custableresponsive td {
    color: #6c6c6c !important;
    font-weight: 500 !important;
}
.custableresponsive th>div {
    font-weight: 600 !important;
  font-size: 14px !important;
}
.custableresponsive th{
  padding: 10px 10px !important;
}
.custableresponsive td {
  font-size: 12px !important;
  color: #848484 !important;
}
.topnavigationsearch {
  padding: 0px 5px;
}
.topnavigationsearch button {
  border: 0px !important;
  color: #3f3d3d !important;
  padding: 0px !important;
}
.navactive{
  font-weight: 700 !important;
}
.latesthandle .custableresponsive table thead {
    display: table;
    table-layout: fixed;
    width: calc(100% - 0px);
    margin-bottom: 3px;
}
.latesthandle .custableresponsive table {
    height: 433px !important;
    flex-grow: 1 !important;
    overflow: hidden !important;
}
.latesthandle .custableresponsive tbody {
    display: block !important;
    overflow: hidden auto !important;
    height: 433px !important;
    max-height: 331px!important;
}
.latesthandle  .custableresponsive tbody tr {
    
    display: table;
    table-layout: fixed;
    width: 100%; 
}
[class*='Search_searchcurreny_'] span svg:first-child {
    bottom: 3px !important;
    font-size: 12px !important; 
}
[class*='Search_searchcurreny_'] span svg:last-child {
    bottom: 3px !important;
    font-size: 12px !important; 
}
.latesthandle h1 {
  color: #575656 !important;
  font-weight: 700 !important;
}

.latesthandle .tabbtnbg button {
  color: #3f3d3d;
  font-weight: 600;
  padding: 5px 10px; 

}
.latesthandle .tabbtnbg button.active {
  background: #3f3d3d;
  color: #fff;
}
.latesthandle .btntable {
    background: #f9f9f9;
    padding: 2px 10px;
    font-size: 12px;
}
.currenyboth h1 {
  padding: 10px 15px;
  background: #fcfcfc;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  color: #3f3d3d !important;
}
.vaolumtext {
  color: #454343 !important;
  font-weight: 600 !important;
}
.allboxesmini {
    grid-template-columns: repeat(6, 1fr);
    display: flex;
    justify-content: space-between;
}
.chartHere {
  padding: 15px 20px;
}
html body .chartHere .borderbottom {
  border-bottom: 0.1px solid #f3f3f3;
}
.allboxesmini h5 {
  color: #929292;
  font-size: 13px;
  font-weight: 400;
  margin-top:10px;
  margin-bottom: 0px;
}
.allboxesmini p {
  color: #575656;
  font-weight: 700;
  margin-bottom: 5px;
}
.widthincrease1 {
  width: 200px;
}
.sellbuytab button { 
  border-radius: 0px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
html body .sellbuytab button.btnb {
  background: #e6f8e6 !important;
  border: 1px solid #a6f2a6 !important;
  color: #417c41 !important;
}
html body .sellbuytab button.btns {
  background: #ffece4 !important;
  border: 1px solid #ffad8c !important;
  color: #c74e1e !important;
}
html body .sellbuytab button.btns.active {
  color: #323232 !important;
  background: #ffad8c !important;
}
html body .sellbuytab button.btnb.active {
  color: #323232 !important;
  background: #a6f2a6 !important;
}
.h3buysell {
  margin-top: 10px;
  margin-bottom: 10px;
}
.h3buysell h3 {
  color: #575656;
  font-weight: 700;
  margin-bottom: 5px !important;
}
.h3buysell h3 span.labelspan {
  color: #929292;
  font-weight: 400;
}
.buysellForm {
  padding: 0px !important;
}  
.Form.ligtMode .inPut {
  background: transparent ;
  border-color: transparent;
  padding: 8px 15px;
  height: auto;
  position: relative;
  overflow: hidden;
}
.Form.ligtMode .inPut>*{
  position: relative;
  z-index: 2;
}
.Form.ligtMode .inPut input+.btinput{
    position: absolute;
    left: 0px;
    right: 0px;
    background: #e0ecf3 ;
    height: 100%;
    width: 100%;
    top: 0px;
    z-index: 1;
    border-radius: 0.5rem;
}
.Form.ligtMode .inPut input:focus-visible+.btinput {
  border: 1px solid #5e5e5e;
}
.Form.ligtMode .inPut h4 {
  margin-bottom: 4px;
  font-size: 12px;
}
.Form.ligtMode .inPut input {
  padding: 0px !important;
  width: 100%;
  text-align: left;
  height: auto !important;
  margin-bottom: 0px !important;
  font-weight: 500;
}
.buysellForm .perBox {
  order: 2;
    justify-content: space-between !important;
    width: 100% !important;
    margin-bottom: 5px !important;
}
.buysellForm .perBox button {
  width: 52px !important;
  padding: 2px 0 !important;
  background: #b8b8b829 !important;
  color: #000;
}
.buysellForm .perBox button:hover {
  background: #5eea5e !important;
}
/* .percentagerange {

  margin-top: 15px;
    height: 1px;
    position: relative;
    background: #e5ecf0;
    width: 100%;
    display: block;
} 
.rdot {
    position: absolute;
    left: 0px;
    width: 7px;
    height: 7px;
    background: #5eea5e;
    border-radius: 20px;
    top: -3px;
}*/
.percentagerangeBuy {

  margin-top: 15px;
    height: 1px;
    position: relative;
    background: #e5ecf0;
    width: 100%;
    display: block;
}
.rdotBuy {
  position: absolute;
  left: 0px;
  width: 7px;
  height: 7px;
  background: #5eea5e;
  border-radius: 20px;
  top: -3px;
}
.percentagerangeSell {

  margin-top: 15px;
    height: 1px;
    position: relative;
    background: #e5ecf0;
    width: 100%;
    display: block;
}

.rdotSell {
    position: absolute;
    left: 0px;
    width: 7px;
    height: 7px;
    background: #5eea5e;
    border-radius: 20px;
    top: -3px;
}
.orderhandle {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.per0 .rdot {
    left: 6%;
}
.per25 .rdot {
    left: 27%;
}
.per50 .rdot {
    left: 48.8%;
}
.per75.rdot {
    left: 70.5%;
}
.per100 .rdot {
    left:auto;
    right: 5.8%;
}
.rdot:before {
    position: absolute;
    content: '';
    width: 10000px;
    right: 0px;
    background: #5eea5e;
    height: 1px;
    top: 3px;
}
.overhide {
  overflow: hidden;
}
.signPage {
  background: #fff;
}
.locktag {
  padding: 10px;
  background: #f6f9ff;
  font-weight: 500;
  color: #424242;
}
.locktag i {
  color: #3684ff;
}
.signPage form {
  background: #fff !important;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0px 24px 0 rgba(0,0,0,.1) !important;
}
.signPage .titleh2 {
    font-size: 30px;
}
.signPage h5 {
  color: #777777;
  font-weight: 600;
}
.signPage h5 a {
  color: #007cff;
}
.signPage form input, .signPage form select, .signPage form textarea, .popHandleIn input, .signPagesamelayout input {
    border: 1px solid #c3d7ff !important;
    border-radius: 4px !important;
    color: #4a4848 !important;
    font-weight: 500;
}
.signPage form input::placeholder{
  color: #4a4848 !important;
}
.signPage form input>div p {
  margin-bottom: 0px !important;
}

.signPage form .btn{
  border-radius: 8px !important;
  background: #3684ff !important;
  padding: 9px 21px !important;
}
.bntandfor a:hover {
  color: #3684ff !important;
}
.borderfullforget {

  border-radius: 4px !important;
  border: 1px solid #c3d7ff !important;
}
.signPage form .borderfullforget{
  margin: 5px 0px !important;
}
.signPage form .borderfullforget input {
  border: 0px !important;
  border-left: 1px solid #c3d7ff !important;
  margin: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.PhoneInputCountry {
  justify-content: center;
  margin: 0px !important;
}
.ExchangePage .latesthandle[class*='SetOrder_so']{
  padding: 15px 25px !important; 
}

.latesthandle .custableresponsive.buysellOrder  [class*='BuyOrderChart'] tbody, .latesthandle .custableresponsive.buysellOrder  [class*='SellOrder'] tbody , .latesthandle .custableresponsive  [class*='MarketHistory_'] tbody {
    max-height: 422px!important;
}


.latesthandle .custableresponsive th:last-child, .latesthandle .custableresponsive td:last-child {
  text-align: right !important;
}
.latesthandle .custableresponsive th:last-child div{
      justify-content: end !important;
}
.bankbtns button{
  display: none;
}
.bankbtns button.active{
  display: block;
}
.bankbtns p {
  font-weight: 600;
}
.signPage form .btn.pendingbtn {
  background: #fba641  !important;
}
.signPage form .btn.submittedbtn{
  background: #46b0ff !important;
}
.signPage form .btn.approvedbtn{
  background: #25c211  !important;
}
.signPage form .btn.rejectedbtn {
  background: #d81947  !important;
}
.ligtModeInfoPage h1 {
  font-weight: 700;
      font-size: 30px;
}
.sn_page_pad h2 {
    font-size: 24px !important;
  font-weight: 600;

}
.ligtModeInfoPage h3 {
  font-weight: 600;
    font-size: 20px;
}
.imported h4, .imported h2  {
  font-weight: 600;
}
.w-70newsimg {
  width: 50%;
  margin: auto;
  float: left;
  margin-right: 15px;
}
[class*='rdt_Table'], nav[class*='sc-kM'] {
  background: #f4f4f4;
}
.signPagesamelayout input {
      width: 100%;
    background-color: #fff;
    color: #454545;
    font-size: 14px;
    padding: 0.8rem;
    border: none;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    outline: none;
  border: 1px solid #7da9ee !important;
    border-radius: 0px !important;
    color: #4a4848 !important;
    font-weight: 500;
}
.signPagesamelayout button {
  border-radius: 8px !important;
    background: #3684ff !important;
    padding: 9px 21px !important;
        border: none;
        color: #fff;
}

.getstarted {
  background: #fff;
}
.getstartedImg {
    height: 80px;
    width: 80px;
    border-radius: 100px;
    margin: auto;
    padding: 13px;
    margin-bottom: 10px;
    border: 6px solid #c7e5ff;
}
.getstarted .row>div:nth-child(2) .getstartedImg {
  border-color: #b27dff;
}
.getstarted .row>div:nth-child(3) .getstartedImg {
  border-color: #ffc9c2;
}
.appbannerIn {
  position: relative;
  padding: 30px 15px;
  width: 80%;

  border: 0px;
  border-radius: 20px;
}
.appbanner {
    background: #faf9ff;
    padding-bottom: 70px;
}
.appbanner:before {
  display: none;
}
.getstarted {
}
.featureSec {
}
.appbannerIn h2 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 28px;
}
.appbannerIn:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0px;
  left: 0px;
  background-image: url('../public/latestimages/home/balanceBanner.png');
}
.appbannerIn img {
    display: inline-block;
    height: 41px;
}
.swal-overlay {
    background-color: rgb(0 0 0 / 69%) !important;
}
.swal-text:first-child {
    margin-top: 15px;
}
.swal-button {
  background: #3f3d3d !important;
}
.shadowmodal {
  position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 69%) !important;
    left: 0px;
    top: 0px;
    z-index: 1;
}
.popHandleIn {
  overflow: hidden;
}
.popnav {
  text-align: center;
}
.popnav h1 {
    background: #3f3d3d0a !important;
    padding: 15px 40px;
    font-weight: 700 !important;
    font-size: 24px !important;
    margin-bottom: 25px;
    color: #2f2f2f !important;
}
.h11 {
    font-weight: 600 !important;
    font-size: 20px !important;
}
.slectblockchian {
    padding: 25px 15px;
    background: #ffffff;
    width: 94%;
    border-radius: 10px;
    border: 1px solid #ececec;
}
.popHandleClose {
    position: absolute;
    right: 15px;
    top: 20px;
}
.popHandleClose svg {
  font-size: 22px;
    color: #2f2f2f !important;

}
.slectblockchian .borderfullforget {
  margin-top: 0px !important;
}

.popHandleIn inPut {
  height: 45px;
}

.inputHandle>div inPut+button, .inputHandle>div inPut+button+button {
  height: 45px;
  background-color: #1598ff;
}

.pad-24 {
  padding: 25px;
}
.stakeMain select, .stakeMain input {
  padding: 0.5rem;
  width: 100%;
}
html body .StakeForm {
  box-shadow: 0 0px 24px 0 rgb(0 0 0 / 3%) !important;
}
.StakeForm .flex>div, .StakeForm .flex>div {
  padding: 0px !important;
  width: 50%;
  margin-bottom: 15px !important;
}
.popHandleIn input {
  border: 1px solid #dbf0ff !important;

    background: #fcfcfc;
}
.tableDesign .custableresponsive tr td  b{
  color: #3a3838 !important;
}

.specialtable tbody tr:nth-child(1), .specialtable tbody tr:nth-child(5) {
    background: #f3f7ff;
}
.specialtable tbody tr:nth-child(2), .specialtable tbody tr:nth-child(4) {
    background: #eff2ff;
}
.specialtable tbody tr:nth-child(3) {
    background: #f9f4ff;
}
.secuityPage{
  background-image: linear-gradient( to bottom, #fff, #dbe4fc 50%, #fff );
}
.mainSecut {
  background: #fff;
      border: 1px solid #ececec !important;
}
.latesthandle h1[class*='BuyOrderChart_title'] {
    color: #549D54 !important;
}

.latesthandle h1[class*='SellOrder_title'] {
    color: #ff6b31 !important;

}
.cryptoSec  .table>tbody {
    vertical-align: initial;
}
html body .navbarMain {
  z-index: 5;
  position: relative;
}

.darkModeText{color:#ffffff !important}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.4rem !important;
}

.react-datepicker__calendar-icon {
  width: 2em !important;
  height: 2.5em !important;
}
.mydaterangepicker{position: relative !important;}
.mydaterangepicker input {
    margin-top: 0px;
    height: 37px;
    margin-left: 5px;
}
.mydaterangepicker .rdrCalendarWrapper{
  position: absolute !important;
 /*  left:-35px; */
  top: 50px !important;

  right: -15px !important; 
 
  z-index: 9 !important;
  box-sizing: border-box !important;
  border: 2px solid #e1e8f6 !important;

}

.portf {
  padding: 15px;
  background: #c8c0c036;
  margin-bottom: 15px;
  border-radius: 8px;

}
.portf label {
  font-weight: 600;
  font-size: 16px;
}
.portf>*{
  
  margin-bottom: 0px;
}
.darkMode .portf>*{
  color: #fff;
  
}
.porth {
  margin-top: 20px;
}
 a.custombtn1 {
  color: #fff;
  border-radius: 8px;
  padding: 8px 25px;
  font-size: 14px;
  background-color: #4a67f0 !important;
}
.custableresponsive td.textdanger, .custableresponsive tr.textdanger td, body.bodylightMode .allboxesmini p.textdanger{
  color:#ff6969 !important;
}
.custableresponsive td.textsuccess, .custableresponsive tr.textsuccess td,body.bodylightMode .allboxesmini p.textsuccess{
  color:#0bc87a !important;
  /* 0bc87a
ff6969 */
}


input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 06px;
  position: relative;
  height: 22px !important;
  padding-left: 0px !important;
  padding: 0px !important;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #e0ecf3 ;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 15px;
  background: #e0ecf3 ;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #f50;
  /*  slider progress trick  */
  box-shadow: -707px 0 0 700px #000;
  margin-top: 0px;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f50;
  /*  slider progress trick  */
  box-shadow: -707px 0 0 700px #ff6b31;
}

.SellHandle input[type="range"]::-webkit-slider-thumb {
  
  box-shadow: -707px 0 0 700px #ff6b31;
  border: 2px solid #ff6b31;
}


.BuyHandle input[type="range"]::-webkit-slider-thumb {
  
  box-shadow: -707px 0 0 700px #549D54;
  border: 2px solid #549D54;
}

.SellHandle input[type="range"]::-moz-range-thumb{
  
  box-shadow: -707px 0 0 700px #ff6b31;
  border: 2px solid #ff6b31;
}


.BuyHandle input[type="range"]::-moz-range-thumb{
  
  box-shadow: -707px 0 0 700px #549D54;
  border: 2px solid #549D54;
}
.buysellForm .SellHandle .perBox  button:hover, 
.buysellForm .SellHandle .perBox  button.active {
    background: #ff6b31 !important;
}
.buysellForm .BuyHandle .perBox  button:hover, 
.buysellForm .BuyHandle .perBox  button.active {
    background: #549D54 !important;
}
/* table tr td {
  word-break: break-all;
} */

/* 
td {
    overflow: hidden;
} */
.buysellForm .orderhandle  .perBox button:disabled, .buysellForm .orderhandle  .perBox button:hover:disabled {
  cursor: not-allowed !important;
  background: #b8b8b829 !important;
  color: #000 !important;
}
.widthlongtd{
  width: 470px !important;
}




.historyTable td {
    /* font-size: 12px !important; */
    color: #848484 !important;
}
.historyTable h3, .historyTable thead th, .historyTable thead th div {
  color: #6c6c6c !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.historyTable tr td {
  font-size: 12px;
  font-weight: 500;
  padding: 0rem 10px !important;
  padding-bottom: 0rem !important;
}
.historyTable tr td {
  color: #848484 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}


.historyTable td button:first-child {
  background-color: #4a67f0 !important;
}
.historyTable .btntable {
  background: #f9f9f9;
  padding: 2px 10px;
  font-size: 12px;
}
.historyTable td button {
  border-radius: 8px !important;
  background-color: #00caa9 !important;
  margin: 2px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0.3rem 1rem;
  border: 0px;
}
th, td  {
  white-space: nowrap;
}
/* .historyTable th, .historyTable td  {
  white-space: nowrap;
} */
.historyTable .table-responsive
{
  padding-right:20px !important;
}
.bodylightMode .popHandle>div.popHandleIn {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 15px 30px rgba(85,85,85,.4);
    left: 50%;
    max-width: 880px;
    min-width: 880px;
    padding-bottom: 5px;
    position: fixed;
    top: 52%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 60%;
    z-index: 10;
}
.popHandleClose {
    align-items: center;
    background: none;
    border: none;
    color: #fff;
    display: flex;
    font-size: 25px;
    justify-content: center;
    outline: none;
}
.popHandleClose svg {
    color: #2f2f2f!important;
    font-size: 22px;
}



[class*='Withdraw_row__'] {
  margin-bottom: 10px;
}
.greenText {
  color:#2b9756 !important;
}
.roundsSection.bgchange h4 a {
  color: #ffaa02 !important;
}

.bgchange {
    background: #f7f7f7;
}
body.bodylightMode [class*='rdt_Table'] {
  background: #f7f7f7  !important;
}
body.bodylightMode nav[class*='sc-kM'] select {
    background-color: #ddd !important;
}
body.bodylightMode .bgchange header {
  background: #f7f7f7 !important;
}
body.bodylightMode .bgchange header button {
  border: 0px;
    background-color: #ddd !important;
}
html body [class*='History_slider__'] .swiper-slide  button {
  display: flex!important;
    width: auto !important;
    height: auto !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    padding: 10px 10px !important;
}

html body [class*='Balance_tg_c__']{
  width: 50px;
  height: 25px;
  background-color: #E1E8F6;
  margin-right: 1rem;
  margin-left: 0.5rem;
  border-radius: 3rem;
  padding: 0.2rem;
  cursor: pointer;
}


html body [class*='Balance_tru_tg_c__']{
    width: 50px;
    height: 25px;
    background-color: #E1E8F6;
    margin-right: 1rem;
    margin-left: 0.5rem;
    border-radius: 3rem;
    padding: 0.2rem;
    cursor: pointer;
}
html body [class*='Balance_tg_sc__'], html body [class*='Balance_tru_tg_c'] [class*='Balance_tru_'] {
  width: 20px;
  height: 20px;
  background-color: #8e8e8e;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
html body [class*='Balance_tru_tg_c'] [class*='Balance_tru_']{
  background-color: #ffac47;
  transform: translateX(23px);
}

body.bodylightMode .popupcenter.buyBoxam_it {
    border: 1px solid #ddd;
    background: #fff;
    max-width: 100%;
}

.popupcenter.buyBoxam_it {
    max-width: 100%;
    margin-top: 20px;
}
h4.bold {
  font-weight: 700;
}
.ReactModal__Content {
  transform: translate(-50%,-50%) !important;
    left: 50% !important;
    top: 47% !important;
    overflow: initial !important;
}

.small_boxes_slider{
  border-radius: 15px;
  border: 0px;
  background: #ffe5c9;
  position: relative;
  padding: 9px 30px;
  padding-left: 30px;
  min-height: 120px;
  overflow: hidden;
  margin-top: 15px;
}
.small_boxes_slider:before {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 115px;
    left: -25px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}
.small_boxes_slider:after {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 115px;
    right: -25px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}

.stars {
  color: #00000040;
}
.stars svg {
  display: block;
  margin-bottom: 5px;
}
.linHere {
      position: absolute;
    height: 100%;
    width: 0.5px;
    background: #00000010;
    left: 54px;
    top: 0px;

}
.small_boxes_slider h4 {
  padding-left: 05px;
}
.ticketSection h4 span.coptext {
  color: #000!important;
  font-size: 14px !important;
}
.ticketSection h4 span {
    color: #ec8708;
}
.bodylightMode{
  
}
.snRank {
  background: #211e1b;
  width: 638px;
  height: 1013px;
  color: #fff;
  position: relative;
  overflow: hidden;
  border-bottom: 10px solid #a8763d;
  margin: auto;
  border: 1px solid #dddddd20;
}
.logoRank {
    padding-top: 40px;
    width: 210px;
}
.rankInfo {
  padding-top: 50px;
  position: relative;
}
.rankuser {
  width: 190px;
  height: 190px;
  border-radius: 500px;
}
.rankuser-sm {
  width: 70px !important;
  height: 70px !important;
 
}
.snRank h3 {
  font-size: 40px;
  color: #febf27;
  font-weight: 700;
}
.snRank h3 span {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.rankimg{
  width: 168px;
    margin-top: 20px;
}
.RankPerInfo {
  padding: 25px 50px;
}
.rankInfo:after {
    position: absolute;
    content: '';
    width: 280%;
    /* background: #ffffff00; */
    background: #84551f;
    height: 134%;
    transform: rotate(-36deg);
    left: -250px;
    top: 3px;
}
.rankInfo:before {
    position: absolute;
    content: '';
    width: 280%;
    /* background: #ffffff00; */
    background: #a8763d;
    height: 134%;
    transform: rotate(-36deg);
    left: -270px;
    top: 3px;
}
.rankInfo > *{
  position: relative;
  z-index: 1;
}
.RankPerInfo p {
  font-size: 30px !important;
  line-height: 34px !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  font-weight: 700;
}
.RankPerInfo p.r {
    color: #ffffff !important;
}
.signature {
    position: absolute;
    right: 0px;
    left: 0px;
    margin: auto;
    bottom: 20px;

    width: 154px;
}
.fontBold{
  
}
.rankuserImgLatest {
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
}
.historyTable #search, .historyTable #search+button {
    height: 45px !important;
    color: #000 !important;
}
.historyTable #search {
  margin-bottom: 20px;
}

/* .phone-input-container {
  display: flex;
  align-items: center;
}

.phone-input-container .borderfullforget {
  flex: 1;
  margin-right: 10px;
} */

.send-otp-button {
 
  padding: 2px 4px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
 
}

.send-otp-button:disabled {
  background-color: #5790ca;
  cursor: not-allowed;

}

.formBottomCOlor a {
  color: #212121 !important;
}
.RankTable  {
  background: #00000020;
  border-radius: 10px;
}
.RankTable tbody tr {
    background: #fffcfccc;
}
table.RankTable>:not(caption)>*>* {
    border-color: #ebebeb !important;
}
.historyTable .RankTable thead th div{
      color: #4e4e4e !important;
}
table.RankTable tbody tr:last-child td {
  border: 0px !important;
}
table.RankTable tr td {
  color: #625f5f !important; 
}
.userImg img {
  border-radius: 12px;
}
html body .ReactModal__Overlay {
    background-color: rgb(0 0 0 / 55%) !important;
}
.form-group {
  margin-top: 10px;
}
.form-group label {
    font-weight: 500;
    color: #0c0c0c82;
    font-size: 16px;
}
.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #8e8e8e;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: 0px;
    right: 0px;
}
 @keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 
.flex1 {
  flex: 1;
}
.controlminheight {
  min-height: auto !important;
    padding: 50px 15px !important;
}
.tableLook>div{
  width: 20%;
  font-size: 14px;
  color: #212121;
}
.tableLook>div:last-child{
  text-align: right;
}
.thLook {
    background: #ffffff10;
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px 10px;
    padding: 10px 15px;
    background: #e3e3e352;
}
.thLook>div{
  font-weight: 600;
}
.tdLook {
  background: #ffffff10;
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 15px;
}
.tdLook {
    background: #00000005;
    border: 1px solid #dddddd7d;
}
.mobShow {
  display: none;
}
.tdLook p {
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 10px;
}
.InfoCom {
  margin: auto;
  width: 70%;
  padding: 2rem;
  border-radius: 1rem;
  background: #fff !important;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, .1) !important;
}
.InfoCom h1 {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
}
.InfoCom h2 {
  font-size: 20px;
  text-transform: uppercase;
}
.InfoCom .leftInfo {
  margin-top: 25px;
  border-left: 3px solid #ddd;
  padding-left: 10px;
  margin-bottom: 20px;
}
.InfoCom h3 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.InfoCom h3 b {
  font-size: 18px !important;
}
.InfoCom h4 b {
  font-size: 18px !important;
}
.InfoCom h4 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.InfoCom .space {
  margin: 15px 0px;
}
.InfoCom table {
  text-transform: uppercase;
}
.sing {
  margin-top: 10px;
  height: 72px;
  border-radius: 5px;
}
html body div .latesthandle .custableresponsive  .nodataImg img {
  height: 160px !important;
  width: auto !important;
  margin: auto;
}
.filter-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  pointer-events: none;
}

.select-container {
  position: relative;
}