@import "../../../Global/Variables.scss";

.app_d,.app_l{
    nav{
      }

      .app{
        height: 700px;

        h1{
            font-size: 45px;
        }
        p{
        }
        img{
            width: 500px;
            margin-top: 1rem;
        }
      }
}


// ***** Dark mode ******

.app_d{
    nav{
    }
    p{
        a{
        }
    }
    .app{
    }
}


// ******** Responsive *********

@media screen and (max-width:550px){
    .app_d,.app_l{
        .app{
            height: 600px;
            h1{
                font-size: 35px;
            }
            img{
                width: 90%;
            }
        }
    }
}

@media screen and (max-width:400px){
    .app_d,.app_l{
        .app{
            height: 500px;
            h1{
                font-size: 30px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

