@import "../../../Global/Variables.scss";

.fail_d,.fail_l{
    nav{
      }

      .fail{
        height: 700px;

        h1{
            font-size: 45px;
        }
        p{
            span{
                cursor: pointer;
            }
        }
        img{
            width: 500px;
            margin-top: 1rem;
        }
      }
}


// ***** Dark mode ******

.fail_d{
    nav{
    }
    p{
        a{
        }
    }
    .fail{
    }
}


// ******** Responsive *********

@media screen and (max-width:550px){
    .fail_d,.fail_l{
        .fail{
            height: 600px;
            h1{
                font-size: 35px;
            }
            p{
                font-size: 14px;
            }
            img{
                width: 90%;
            }
        }
    }
}

@media screen and (max-width:400px){
    .fail_d,.fail_l{
        .fail{
            height: 500px;
            h1{
                font-size: 28px;
            }
            p{
                font-size: 12px;
            }
        }
    }
}


