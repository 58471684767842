@import "../../Global/Variables.scss";

.signin_d,.signin_l{
  background-image: url("../../Assets/hero_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  nav{

  }

  ._signin {

    /*height: 600px;*/

    h1 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: $link;
      width: 100%;
      margin-bottom: 1rem;
    }

    form {
      background-color: $form_l;
      box-shadow: 0 15px 30px rgb(85 85 85 / 6%);
      padding: 2rem;
      border-radius: 1rem;

  .warning_note{
    p{
      font-size: 14px;
      position: relative;
      top: -5px ;
      color: $neg;
    }
  }

      input {
        width: 100%;
        background-color: $light;
        color: $gray;
        font-size: 14px;
        padding: 0.8rem;
        border: none;
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        outline: none;
        &::placeholder {
          color: $l_ph;
        }
      }
      .sbox {
        padding-right: 1rem;
        border-radius: 0.5rem;
        width: 100%;
      }

      .sc {
        width: 100%;
        .sbox {
          width: 49%;
        }
        input {
          width: 49%;
        }
      }
      .password {
        border-radius: 0.5rem;
        width: 100%;
        margin: 0rem 0;
        input {
          margin: 0;
          padding-left: 0.8rem;
        }

        .eye , .eyea {
          background: none;
          outline: none;
          border: none;
          font-size: 16px;
          color: $l_ph;
          transition: $t_normal;

          &:hover {
            color: $btnbg;
          }
        }
        .eyea{
            color: $btnbg;
        }
      }
    }

      .signin_btn {
        margin-top: 1rem;
        border-radius: 2rem;
      }

      .refer{

        margin-top: 1rem;
        
        padding: .5rem 1rem;
        border-radius: .5rem;

        a{
          font-size: 14px;
          color: $link;
          transition: $t_normal;

          &:hover{
            color: $btnbg;
          }
        }
      }
    }

    img {
    }
    }


// ******* Dark mode ********

.signin_d{
  background-image: url("../../Assets/hero_dark_bg.png");
  nav{
    background-color: $register_nav;
  }
  ._signin {
    form {
      background-color: $form_d;
      h1,
      .terms {
        color: $light;
      }
    }
  }
}



// ********** Responsive *************

@media screen and (max-width:900px){
 ._signin{
  margin-top: 0;
  position: relative;
  height: 400px !important;
  width: 600px !important;
  form{
    width: 100% !important;
    position: relative;
    z-index: 2;
  }
   img{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 70% !important;
    transform: translate(-50% , -50% );
    opacity: 0.3;
  }
 }
}

@media screen and (max-width:550px){
  ._signin form{
    padding: 1.5rem 1rem !important;
  }
}

@media screen and (max-width:400px){
  ._signin .refer{
    a{
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width:350px){
  ._signin .refer{
    a{
      font-size: 10px !important;
    }
  }
}

@media screen and (max-width: 370px) {
  ._signin{
    form {
      min-width: 100% !important;
      width: 100% !important;
      .sc{
        flex-direction: column;
        margin: .5rem 0rem;
        margin-bottom: 0;
        .sbox{
          width: 100% !important;
        }
        input{
          width: 100% !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
