body.bodydarkMode {
    color: #909090;

    background: #222222 !important;
}
body.bodydarkMode h1, body.bodydarkMode h2, body.bodydarkMode h3, body.bodydarkMode h4, body.bodydarkMode h5, body.bodydarkMode h6 {
    color: #ffffff;
}
body.bodydarkMode .stakeMain h1, body.bodydarkMode .stakeMain h2, body.bodydarkMode .stakeMain h3, body.bodydarkMode .stakeMain p, body.bodydarkMode .stBox h4, body.bodydarkMode .inviteFull h4, body.bodydarkMode [class*='Refferal_reff_']  h4, 
body.bodydarkMode [class*="News_news_"] [class*="News_boxes"]  [class*="News_box"] h3{
    color: #ffffff !important;
}
body.bodydarkMode .snBox h4, body.bodydarkMode h3, body.bodydarkMode h4, body.bodydarkMode h5, body.bodydarkMode h6, body.bodydarkMode .signPage h4, body.bodydarkMode .bankbtns p, html body.bodydarkMode .signPage .padSecimporant form h5, html body.bodydarkMode .tbalehead [class*='History_filters_'] h3, body.bodydarkMode .secuityPage [class*='Security_secu__'] [class*='Security_box_'] h4 , body.bodydarkMode .secuityPage [class*='Security_secu__'] [class*='Security_box_'] p, 
body.bodydarkMode [class*="News_news_"] [class*="News_boxes"]  [class*="News_box"] p {
    color: #e6e6e6;
}
body.bodydarkMode .bgColor, body.bodydarkMode .ExchangePage {
    background: #222222 !important;
}
body.bodydarkMode .navbarMain {
    background: transparent !important;
}
body.bodydarkMode .navbarMain .container>div.bg:before {
    background: #282828;
}
body.bodydarkMode.sliderHero h1 {
    color: #fff;
}
body.bodydarkMode .navbarMain .navlinks a, body.bodydarkMode .sliderHero .pHighlight, body.bodydarkMode .snBox h3, body.bodydarkMode .cryptoIn .tabbtn, body.bodydarkMode .fLink a   {
    color: #a8a8a8;
}
body.bodydarkMode .snBox>div.bgbox, body.bodydarkMode .newListing:before, body.bodydarkMode .newListing:after {
    display: none;
}
body.bodydarkMode .snBoxlisting {
    background: #ffffff0d;
    box-shadow: none;
}
body.bodydarkMode .Adds:before, body.bodydarkMode .featureSec, body.bodydarkMode .Partners, html body.bodydarkMode  footer {

    background: #ffffff05 !important;
}
body.bodydarkMode .banners {
    border-color: #ffffff10;
}
body.bodydarkMode .cryptoIn {
    background: #ffffff08;
}
body.bodydarkMode .cryptoIn .tabbtn.active {
    color: #fff;
}
body.bodydarkMode .table>:not(caption)>*>* {
    border-color: #f9f9f90a !important;
}
body.bodydarkMode .latestdesigntable th, body.bodydarkMode [class*='Fees_fees_'] [class*='Fees_fees_wrapper'] table th {
    color: #d2d0d0 !important;
}
body.bodydarkMode [class*='Fees_fees_'] [class*='Fees_fees_wrapper'] table thead {
        background-color: rgb(225 232 246 / 2%);
}
body.bodydarkMode .navbarMain .navlinks a.wIcon {
    color: #fff !important;
}
body.bodydarkMode .latestdesigntable tr td, body.bodydarkMode  .navbarMain .navlinks a {
    color: #e6e6e6 !important;
}
 body.bodydarkMode  .navbarMain .navlinks a {
    color: #e6e6e6 !important;
}
 body.bodydarkMode  .navbarMain .navlinks a[class*='Navbar_active_nav_link__'] {
    color: #ffaa02 !important;
}
html body.bodydarkMode  footer {
    border-top: 1px solid #f9f9f90a;
}
body.bodydarkMode .SocialLinkFooter a {
    background-color: #f9f9f90a;
    color: #d2d0d0;
}

html body.bodydarkMode  footer+div {
    background-color: #f7f7f70a !important;
    color: #fff !important;
}
body.bodydarkMode .btnslabel .snbtnlabel>div {
    background: #faf9ff26;
}
body.bodydarkMode  .btnslabel .snbtnlabel>div i, body.bodydarkMode  .btnslabel .snbtnlabel .btnhere a {
    background: #e3e7ff26;
    color: #fff !important;
}
body.bodydarkMode  .btnslabel .snbtnlabel>div>div i+span {
    color: #fff;
}
body.bodydarkMode  .btnslabel>div:nth-child(2) .snbtnlabel>div {
    background: #fff8f026;
}

body.bodydarkMode  .btnslabel>div:nth-child(2) .btnhere a, body.bodydarkMode  .btnslabel>div:nth-child(2) .snbtnlabel>div>div i {
    background: #ffead126;
    color: #fff !important;
}
body.bodydarkMode  .btnslabel>div:nth-child(3) .snbtnlabel>div {
    background: #fff3fd26;
}

body.bodydarkMode  .btnslabel>div:nth-child(3) .btnhere a, body.bodydarkMode  .btnslabel>div:nth-child(3) .snbtnlabel>div>div i {
    background: #ffddf926;
    color: #fff !important;
}

body.bodydarkMode  .btnslabel>div:nth-child(4) .snbtnlabel>div {
    background: #fff8f026;
}

body.bodydarkMode  .btnslabel>div:nth-child(4) .btnhere a, body.bodydarkMode  .btnslabel>div:nth-child(4) .snbtnlabel>div>div i {
    background: #ffead126;
    color: #fff !important;
}
body.bodydarkMode  .btnslabel .snbtnlabel .btnhere a:hover{
    background: #e3e7ff4a;
}

body.bodydarkMode  .latestdesigntable tr td  a{
 color: #e6e6e6 !important;
}
body.bodydarkMode  .latestdesigntable tr td  a:hover {
 color: #ffaa02 !important;;
}
body.bodydarkMode .diwali2, body.bodydarkMode .diwali1  {
    display: none;
}
body.bodydarkMode .ExchangePage .latesthandle {
    background-color: #282828 !important;
}
body.bodydarkMode .ExchangePage .latesthandle .topnavigationsearch button {
    color: #e6e6e6 !important;
}
body.bodydarkMode .latesthandle input,body.bodydarkMode .inputdark {
    background: #3e3e3e !important;
    color: #ffffff !important;
    border: 0px !important;
}
body.bodydarkMode .latesthandle input::placeholder, body.bodydarkMode .custableresponsive th, body.bodydarkMode .custableresponsive th, body.bodydarkMode .allboxesmini p, body.bodydarkMode  .vaolumtext {
    color: #e6e6e6 !important;
    font-weight: 600 !important;
}

body.bodydarkMode .custableresponsive td, body.bodydarkMode .allboxesmini h5, body.bodydarkMode [class*='Fees_fees_'] [class*='Fees_fees_wrapper'] table td {
    color: #c0c0c0 !important;
    font-weight: 400 !important;
}
body.bodydarkMode  .currenyboth h1 {
    background: #fcfcfc0f;
    border-color: #e5e5e50a !important;
    color: #ffffff !important;
}
html body.bodydarkMode .chartHere .borderbottom {
    border-color: #f3f3f314;
}
body.bodydarkMode .latesthandle h1, body.bodydarkMode .latesthandle .tabbtnbg button, body.bodydarkMode .buysellForm .perBox button, body.bodydarkMode .Form.ligtMode .inPut h4 span {
    color: #e6e6e6 !important;
}
html body.bodydarkMode  .latesthandle  h1[class*='BuyOrderChart_title'] {
    color: #a6f2a6 !important;
    font-weight: 600 !important;
}
html body.bodydarkMode  .latesthandle  h1[class*='SellOrder_title'] {
    color: #ff6b31 !important;
    font-weight: 600 !important;
}
body.bodydarkMode .Form.ligtMode .inPut input+.btinput {
    background: #3e3e3e;
}
body.bodydarkMode .percentagerange {
    background: #e5ecf024;
}
body.bodydarkMode .latesthandle .btntable {
    background: #f9f9f924;
    color: #e6e6e6 !important;
}

body.bodydarkMode .ligtMode.cusHero, body.bodydarkMode .ligtMode.signPage{
    background: #222222 !important;
}
body.bodydarkMode [class*='ContactUs_texts'] h3 {
    background-color: #ededed0d !important;
        color: #ffffff !important;
}
body.bodydarkMode [class*='ContactUs_contact_info'] {
    background-color: #f6f9ff0d !important;
}
body.bodydarkMode .signPage form {
    background: #2e2e2e63 !important;
    border: 1px solid #f1f1f11c;
}
body.bodydarkMode .signPage form input, body.bodydarkMode .signPage form select, body.bodydarkMode .signPage form textarea, body.bodydarkMode .borderfullforget, body.bodydarkMode .signPagesamelayout input, html body.bodydarkMode  .rdrDateDisplayItem input{

        border-color: #7da9ee1f !important;

    border: 0px !important;
    color: #e6e6e6 !important;
    background: #464646  !important;
}
body.bodydarkMode .signPagesamelayout input::placeholder {
    color: #fff;
}
body.bodydarkMode  .signPage form .borderfullforget input {

    border-color: #7da9ee1f !important;
}
body.bodydarkMode input:-webkit-autofill, body.bodydarkMode input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px #464646     inset;
}
body.bodydarkMode input:-webkit-autofill, body.bodydarkMode input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px #ffffff inset;
}
body.bodydarkMode .signPage form .borderfullforget input {
    border-left: 0px !important;
}
body.bodydarkMode .signPage form input::placeholder, body.bodydarkMode .signPage form textarea::placeholder{
    
    color: #e6e6e6 !important;
}
body.bodydarkMode  .ligtMode [class*="Navbar_box__"] {
    background-color: #3c3c3c !important;
    border-color: #ececec0d !important;
}
body.bodydarkMode .dropMenu a i, body.bodydarkMode .dropMenu button i [class*="Navbar_box__"] a, body.bodydarkMode [class*="Navbar_box__"] button, body.bodydarkMode [class*="Navbar_box__"] svg path {
    color: #ffffff !important;
    stroke: #ffffff !important;
}
body.bodydarkMode .locktag {
    background: #f6f9ff05;
    color: #b4b4b4;
}
body.bodydarkMode .bntandfor a{
    color: #fff !important;
}
body.bodydarkMode .bntandfor a:hover{
    color: #3684ff  !important;
}
body.bodydarkMode [class*="Register_terms__"] p {
    color: #e6e6e6 !important;
}
body.bodydarkMode .appbanner {
    background: #ffffff05 !important;
}
body.bodydarkMode .looterpage:before {
    display: none;
}

body.bodydarkMode  .drawBox {
    background: #2c2c2c08 !important;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 46%);
}
body.bodydarkMode  .drawHead {
    background: #1d1d1d;
}
body.bodydarkMode  .snvalue {
    background: #2f2f2f;
    border: 1px solid #dbe0fa14;
}
body.bodydarkMode  .roundsSection {
    background: #000;
    background: #1f1f1f;
}
body.bodydarkMode  .snticket h3, body.bodydarkMode  .snticket h2 {
    color: #000;
}
body.bodydarkMode .lightsnvalue.snvalue p {
    color: #909090 !important;
    font-weight: 500;
}
body.bodydarkMode .popupcenter {
    background: rgb(24 24 24) !important;
    border: 1px solid rgb(204 204 204 / 9%);
}
body.bodydarkMode .popupcenter .modalHeader {
    border-bottom: 1px solid #dddddd1a;
    background: #ffffff08;
}
body.bodydarkMode .popupcenter .modalHeader h2 {
    color: #fff;
}

body.bodydarkMode .popupcenter .inPut {
    background: #ecefff12;
    padding: 10px 15px;
    border-radius: 15px;
    border: 1px solid #dbe0fa1a;
    margin-bottom: 10px;
}
body.bodydarkMode .popupcenter .inPut input , body.bodydarkMode .popupcenter .modalBody label, body.bodydarkMode .popupcenter .valuemodal p, body.bodydarkMode .popupcenter .inPut p,  body.bodydarkMode .popupcenter .modalHeader button{
    color: #fff;
}
body.bodydarkMode .popupcenter .inPut input.form-control:focus {
    color: #fff;
    background-color: #f4f6ff05;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

body.bodydarkMode [class*="ContactUs_box_"] {
    background-color: #414141 !important;
}
body.bodydarkMode [class*="ContactUs_box_"] p {
    color: #fff !important;
}
body.bodydarkMode .tbalehead {
    background: transparent;
}
body.bodydarkMode [class*="Balance_bal_"] table tr {
    border-spacing: 0rem;
}
body.bodydarkMode table tr td, body.bodydarkMode .tableDesign .custableresponsive tr td, body.bodydarkMode .tableDesign th{
        border-bottom:1px solid  !important;
        border-color: #323232 !important;
}
@media (min-width: 767px) {
    body.bodydarkMode .tableDesign tbody tr:hover {
        background: #0000000d !important;
        box-shadow: none;
    }
}
body.bodydarkMode .cusSelect select {
    background-color: #2d2d2d;
    color: #fff !important;
}
/*for chrome*/
body.bodydarkMode .custableresponsive::-webkit-scrollbar-track-piece,body.bodydarkMode  ::-webkit-scrollbar-track-piece, body.bodydarkMode .table-responsive::-webkit-scrollbar-track-piece, body.bodydarkMode .product_category_handle::-webkit-scrollbar-track-piece, body.bodydarkMode .scroll_checkout_pages::-webkit-scrollbar-track-piece  {
    background-color: #323232;
}

/* Handle */
body.bodydarkMode .custableresponsive::-webkit-scrollbar-thumb,body.bodydarkMode  ::-webkit-scrollbar-thumb, body.bodydarkMode .table-responsive::-webkit-scrollbar-thumb, body.bodydarkMode .product_category_handle::-webkit-scrollbar-thumb, body.bodydarkMode .scroll_checkout_pages::-webkit-scrollbar-thumb {
  background: #b8b8b8 !important;

}
/* Handle on hover */
body.bodydarkMode .custableresponsive::-webkit-scrollbar-thumb:hover,body.bodydarkMode  ::-webkit-scrollbar-thumb:hover, body.bodydarkMode .table-responsive::-webkit-scrollbar-thumb:hover, body.bodydarkMode .product_category_handle::-webkit-scrollbar-thumb:hover, body.bodydarkMode .scroll_checkout_pages::-webkit-scrollbar-thumb:hover {
  background: #8a8383 !important;
}

/*for firebox*/
body.bodydarkMode .custableresponsive, body.bodydarkMode , body.bodydarkMode .table-responsive, body.bodydarkMode .product_category_handle, body.bodydarkMode .cart_height_set, body.bodydarkMode .scroll_checkout_pages, body.bodydarkMode .scroll_order_pages, body.bodydarkMode .custableresponsive {
    scrollbar-color: #b8b8b8 #323232 !important;
    scrollbar-width: thin !important;
}
body.bodydarkMode  .signPage {
    background: #222222  !important;
}
body.bodydarkMode .inviteFull, body.bodydarkMode .stakeMain h2.inviteBox {
    background-color: #c4d8ff0d !important;
}

body.bodydarkMode .specialtable tbody tr:nth-child(1), body.bodydarkMode .specialtable tbody tr:nth-child(5) {
    background: #f3f7ff05;
}
body.bodydarkMode .specialtable tbody tr:nth-child(2), body.bodydarkMode .specialtable tbody tr:nth-child(4) {
    background: #eff2ff05;
}
body.bodydarkMode .specialtable tbody tr:nth-child(3) {
    background: #f9f4ff05;
}
body.bodydarkMode .tableDesign .custableresponsive tr td b {
    color: #fff !important;
}
body.bodydarkMode [class*="sc-fL"], body.bodydarkMode [class*="sc-eD"], body.bodydarkMode [class*='rdt_Table'], body.bodydarkMode nav[class*='sc-kM'], body.bodydarkMode #search, body.bodydarkMode #search+button {
    background: #242424 !important;
}
body.bodydarkMode .rdt_Table {
    padding: 1px 0px !important;
    background: #2e2d2d !important;
    margin: 0%;
    width: 100%;
}
body.bodydarkMode .rdt_TableRow:hover .rdt_TableCell{
    background: #0000000d !important;
}


body.bodydarkMode [class*='rdt_TableCell'] {
    padding: 15px 15px !important;
}
body.bodydarkMode [class*='rdt_Table'] *{
    border-color: #323232 !important;
}
body.bodydarkMode #search, body.bodydarkMode #search+button  {
    border: 1px solid;
    border-color: #4d4d4d;
    border-color: #353535;
}
body.bodydarkMode [class*="sc-fL"] *, body.bodydarkMode [class*="sc-eD"] *, body.bodydarkMode [class*='rdt_Table'] *, body.bodydarkMode nav[class*='sc-kM'] * {
    color: #e6e6e6 !important;
    background-color: transparent;
}
body.bodydarkMode nav[class*='sc-kM'] select {
    background-color: #1d1c1c !important;
}
body.bodydarkMode [class*='rdt_TableHeadRow'] * {
    color: #e6e6e6 !important;
    color: #6c6c6c !important;
    font-size: 14px !important;
    font-weight: 600 !important;
 }
body.bodydarkMode [class*="sc-jI"] button {
    fill: #e6e6e6 !important;
}
body.bodydarkMode .signPage form .borderfullforget span {
    background-color: rgb(28 28 28 / 26%);
    color: #ffffff;
}

body.bodydarkMode .tabbtns button.btn, body.bodydarkMode [class*="Fees_fees_"] [class*="Fees_tabs"] button {
  background: #363636 !important;
  }
body.bodydarkMode .tabbtns button.btn[class*="History_active"], body.bodydarkMode [class*="Fees_fees_"] [class*="Fees_tabs"] [class*="Fees_active"]  {

  background: #6b6b6b !important;
}
body.bodydarkMode  [class*="PaginationBox_page_"] button {
    background-color: #ffffff08;
    border-color: #373737;
}
body.bodydarkMode .secuityPage {
    background: transparent ;
}
body.bodydarkMode .mainSecut {
    background: #ffffff05;
    border: 1px solid #323232 !important;
}
body.bodydarkMode  [class*="Security_title_"] {
    background: #363636;
    outline: 1px solid #323232 !important;
    border: 2px solid #323232 !important;
    color:  #fff !important;
}
body.bodydarkMode [class*="Navbar_box__"] {
    border: 1px solid #3b3b3b !important;
    background-color: #292929 !important;
}
body.bodydarkMode [class*="Coininfo_coininfo_"]  [class*="Coininfo_mySwiper"]  button {
    color: #fff;
}
body.bodydarkMode [class*="NewsDetails_news"] [class*="NewsDetails_title"] h1 {
    color: #fff !important;
}

body.bodydarkMode [class*="Coininfo_coininfo_"]  [class*="Coininfo_mySwiper"]  button[class*="Coininfo_active"]  {
    color: #ffaa02;
}
body.bodydarkMode [class*="Coininfo_coininfo_"]  [class*="Coininfo_mySwiper"] {
    background-color: rgb(211 233 248 / 3%);

}
body.bodydarkMode [class*="Fees_fees_"], body.bodydarkMode [class*="News_news_"] [class*="News_boxes"]  [class*="News_box"] {
    background-color: transparent;
    box-shadow: none;
}
body.bodydarkMode [class*="News_news_"] [class*="News_boxes"]  [class*="News_box"] {
    border: 1px solid #3b3b3b !important;
}

body.bodydarkMode [class*="NewsDetails_back"] {
    color: #808080 !important;
}

body.bodydarkMode .popHandleIn, .swal-modal {
    background: #222222 !important;
    box-shadow: none !important;
}
body.bodydarkMode .popnav h1{
    background: #c2c2c20a !important;
    color:  #fff !important;
}
body.bodydarkMode .slectblockchian h1, .swal-text {
    color:  #fff !important;
}
body.bodydarkMode .slectblockchian {
    background: #ffffff08!important;
    border-color: #ececec12!important;
}
body.bodydarkMode .slectblockchian select {
    background-color: transparent !important;
    color: #fff;
}
body.bodydarkMode [class*="Deposit_"] [class*="Deposit_qrcode"] span{
    color: #fff !important;
}

body.bodydarkMode [class*="Deposit_depositall"] [class*="Deposit_impn_"] {
    background-color: rgb(255 66 66 / 10%);
    color: #fff;
}
body.bodydarkMode [class*="Deposit_depositall"] [class*="Deposit_impn_"] h3 {

    color: #ff5454 !important;
}
body.bodydarkMode .popHandleClose svg {

    color: #fff !important;
}
body.bodydarkMode .popHandleIn input {
    border-color:   #dbf0ff17 !important;
    background: #fcfcfc0a;
    color: #ddd !important;
}
input {
}
body.bodydarkMode .inputHandle>div h3 {
    background-color: #ffffff20 !important;
    border-color:   #dbf0ff17 !important;
    color: #fff !important;
}
body.bodydarkMode .popHandleIn form>button {
    background-color: #7bbdff !important; 
}
body.bodydarkMode  [class*="Stack_nodata"] {
    background-color: #ffffff10 !important;
}
body.bodydarkMode  [class*="Stack_nodata"] h1 {
    color: #fff !important;
}
html body.bodydarkMode .mobMenuLight {
    background: #222222 !important;
}
html body.bodydarkMode .appbannerIn:before {
    opacity: 0.5;
}
body.bodydarkMode .custableresponsive td.textdanger,body.bodydarkMode .custableresponsive tr.textdanger td, body.bodydarkMode .allboxesmini p.textdanger{
    color:#ffad8c !important;
}
body.bodydarkMode .custableresponsive td.textsuccess,body.bodydarkMode .custableresponsive tr.textsuccess td, body.bodydarkMode .allboxesmini p.textsuccess{
color:#a6f2a6 !important;
}
html body.bodydarkMode [class*='nodata'] {
  background: transparent;
}
html body.bodydarkMode [class*='nodata'] h1 {
  color: #fff;
}

html body.bodydarkMode  .mydaterangepicker .rdrCalendarWrapper {
    background: #363636 !important;
    border-color: #363636 !important;
}
html body.bodydarkMode .rdrDateDisplayWrapper {
    background-color: #222222 !important;
}
.rdrDateDisplayItem input {
    margin-top: 0px;
    margin-left: 0px;
    border: 0px !important;
}

html body.bodydarkMode .rdrMonthAndYearPickers select, html body.bodydarkMode  .rdrMonthPicker {
    color: #fff;
    background: #222222;
}

html body.bodydarkMode .rdrDayNumber span {
    color: #fff;
}
html body.bodydarkMode .rdrDayPassive {
    opacity: 0.3;
}
html body.bodydarkMode [class*='sc-cy'] {
    background: transparent !important;
}

html body.bodydarkMode [class*='MiniMarket_arrow'] {
    color: #fff !important;
}


html body.bodydarkMode [class*='Search_searchcurreny_'] {
    box-shadow: 0 2px 30px rgb(0 0 0 / 50%);
    border: 1px solid #3a3a3a !important;
}
html body.bodydarkMode .portf {
      color: #fff;
}
html body.bodydarkMode .rdrDateDisplayItem {
    border: 0px solid transparent !important;
}
html body.bodydarkMode .rdrNextPrevButton {
    background: #6b6b6b !important;
    color: #fff !important;
}
html body.bodydarkMode  .rdrPprevButton i {
    border-color: transparent rgb(255 255 255) transparent transparent;
}
 html body.bodydarkMode  .rdrNextButton i {
    border-color: transparent transparent transparent rgb(255, 255, 255);
}

body.bodydarkMode .historyTable td {
    color: #c0c0c0 !important;
    font-weight: 400 !important;
}
body.bodydarkMode .historyTable td,body.bodydarkMode .historyTable th {
    border-bottom: 1px solid !important;
    border-color: #323232 !important;

}
body.bodydarkMode .historyTable .btntable {
    background: #f9f9f924;
    color: #e6e6e6 !important;
  }

.bodydarkMode .popHandle>div.popHandleIn{

    border-radius: 1rem;
    box-shadow: 0 15px 30px rgba(85,85,85,.4);
    left: 50%;
    max-width: 880px;
    min-width: 880px;
    
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 60%;
    z-index: 10;
}

body.bodydarkMode #search {
        height: 33px;
}
body.bodydarkMode #search+button {
    border-left: 0px solid;
}
body.bodydarkMode .gr-cl{color:#909090 !important}

body.bodydarkMode .small_boxes_slider {
    background: #ffe5c917;
}
body.bodydarkMode .small_boxes_slider:before, body.bodydarkMode .small_boxes_slider:after {
    background: #1f1f1f;
}
body.bodydarkMode .stars {
    color: #ffffff40;
}
body.bodydarkMode .g-details p {
    color: #ffffff;
}
body.bodydarkMode .linHere {
    background: #ffffff10;
}

body.bodydarkMode .formBottomCOlor a {
  color: #fff !important;
}

body.bodydarkMode .historyTable #search, body.bodydarkMode .historyTable #search+button {
    color: #fff !important;
    background: #00000052 !important; 
}
body.bodydarkMode .historyTable .RankTable thead th div {
    color: #c1c1c1 !important;
}
body.bodydarkMode .RankTable tbody tr {
    background: #202020cc;
}
body.bodydarkMode .form-group label {
    color: #ffffffb8;
}

body.bodydarkMode  .thLook {
  background: #5a5a5a52;
}

body.bodydarkMode .tableLook>div{
  color: #fff;
}

body.bodydarkMode .tdLook {
  background: #ffffff10;
  border-width: 0px;
}

body.bodydarkMode .InfoCom {
    background: #ffffff12 !important;
    border: 1px solid #f1f1f112;
}
body.bodydarkMode .InfoCom table th, body.bodydarkMode .InfoCom table td {
    color: #fff;
}
body.bodydarkMode .InfoCom tbody, body.bodydarkMode  .InfoCom td, body.bodydarkMode  .InfoCom  tfoot, body.bodydarkMode  .InfoCom  th, body.bodydarkMode  .InfoCom  thead, body.bodydarkMode  .InfoCom  tr {
    border-color: #dee2e614;
}
body.bodydarkMode .loader {
   
    /* border: 3px solid #FFF; */
}