
.looterpage.sn_page_pad h3 {
    margin-top: 15px;
    font-size: 25px !important;
    font-weight: 600;
}
.lottery1 {
    width: 12%;
    position: absolute;
    left: 15%;
    animation: left1 10s linear infinite;
    top: 50px;
}
@keyframes left1 {
0%{
  transform: scale(1);
}
50%{
  transform: scale(0.8);
}
100%{
  transform: scale(1);
}
}
.lottery2 {
    width: 12%;
    position: absolute;
    right: 15%;
    top: 50px;
    animation: right1 5s linear infinite;

}

@keyframes right1 {
0%{
  transform: scale(1);
}
50%{
  transform: scale(0.8);
}
100%{
  transform: scale(1);
}
}
.looterpage h1 {
	font-weight: 700;
	font-size: 50px;
	    color: #131740;
}
.looterpage  {
    color: #000;
}
.buybtnouter {
    animation: left1 10s linear infinite;
    position: relative;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    padding: 15px 40px;
    margin-top: 18px;
    border-radius: 9px;
    background: #ffba3b !important;
    box-shadow: none;
    background: url(../../Assets/lottery/luckydraw.png) !important;
    background-size: cover !important;
    background-position: 20% !important;
}



.looterpage .buybtn {
    border-radius: 0!important;
    /* margin-top: 5px; */
    padding: 7px 30px;
    background: #252525d9;
    border-radius: 5px !important;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
}

.looterpage .buybtnouter:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,right top,left top,from(#fff),to(transparent));
    -webkit-animation: right 2s linear infinite;
    animation: right 2s linear infinite
}

.looterpage .buybtnouter:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,left top,right top,from(#fff),to(transparent));
    -webkit-animation: left 2s linear infinite;
    animation: left 2s linear infinite
}

.looterpage .buybtnouter span:before {
    top: 0;
    left: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(transparent));
    -webkit-animation: top 2s linear infinite;
    animation: top 2s linear infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.looterpage .buybtnouter span:after, .looterpage .buybtnouter span:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%
}

.looterpage .buybtnouter span:after {
    bottom: 0;
    right: 0;
    background: -webkit-gradient(linear,left bottom,left top,from(#fff),to(transparent));
    -webkit-animation: bottom 2s linear infinite;
    animation: bottom 2s linear infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

@-webkit-keyframes left {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@keyframes left {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@-webkit-keyframes right {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@keyframes right {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@-webkit-keyframes top {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@keyframes top {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@-webkit-keyframes bottom {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@keyframes bottom {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

.buybtnouter:hover .buybtn {
	background: #311b1bd9;
}
.ticketSection h2 {
    font-weight: 700;
    font-size: 30px !important;
}
.ticketSection h4 {
    font-size: 20px !important;
}
.ticketSection h4 span {
    font-size: 26px !important;
	color: #ffaa02;
    font-weight: 700;
}
.drawBox h1, .drawBox h2, .drawBox h3, .drawBox h4, .drawBox p {
	margin: 0px !important;
}
.drawBox {
    background: #f9faff !important;
    width: 60%;
    margin: auto;
    margin-top: 25px;
    border-radius: 8px;
    overflow: hidden;
        box-shadow: 0px 19px 20px rgb(215 216 223 / 46%)
}
.drawHead {
	background: #dfdfdf;
	padding: 15px;
}
.drawHead h5 {
    font-weight: 700;
}
.drawHead h5, .drawHead p{
	margin: 0px !important;
}
.drawBody  {
	padding: 15px 0px;
	text-align: left;
	padding-bottom: 0px;
}
.sndrow {
	margin-bottom: 20px;
	padding: 0px 15px;
}
.mainMidal {
	padding: 0px 15px;
        transition: all .5s ease 0s;
}
.drawBody  h2 {
    font-size: 20px !important;
}
.drawBody  h1 {
    font-size: 30px !important;
    font-weight: 700;
}
.snvalue {
	margin-top: 20px;
}
.snvalue h4 {
    font-weight: 700;
    font-size: 19px !important;
    margin-bottom: 4px !important;
}
.snvalue h2 {
    font-size: 26px !important;
    color: #6476f8;
}
.snvalue p {
    font-size: 14px !important;
}
.hideshow {
	background:#f0f3ff;
	padding: 10px;
	text-align: center;
	margin-top: 20px;
	cursor: pointer;
    position: relative;
    z-index: 3;
}
.roundsSection, .ticketSection{
	position: relative;
	overflow: hidden;
}
.ticketSection:before , .roundsSection:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 0100%;
    opacity: 0.5;
    background-image: url("../../../public/128.png");
    background-size: cover;
    background-position: center;
    left: 0px;
    top: 0px;
    background-attachment: fixed;
    opacity: 0 !important;
}
.ticketSection:before {
    opacity: 1;
}
.roundsSection:before {
    opacity: 0.5;
}
.roundsSection{
    background: #fff;
}
.roundsSection .react-tabs__tab-list {
	border: 0px;
	display: flex;
	justify-content: center;
	gap: 10px;
}
.roundsSection .react-tabs__tab {
    background: #fff;
    border-radius: 19px;
    padding: 4px 20px;
    padding-top: 6px;
    border: 1px solid #ddd;
    font-weight: 600;
}
.roundsSection .react-tabs__tab.react-tabs__tab--selected {
	border: 0px;
	color: #fff;
	background: #6476f8;
    border: 1px solid #6476f8;
}
.roundsSection .drawHead {
    background: #ffffff;
}
.roundsSection .drawBox {
    background: #ebeeff !important;
    border: 1px solid #e5e5e5;
}
.numberround {
    background: #fff;
    border-radius: 19px;
    padding: 2px 10px;
    padding-top: 5px;
    border: 1px solid #bfbfbf;
    font-weight: 500;
    color: #000 !important;
    font-size: 12px;
}
.drawBoxrighticoms button{
	border: 0px;
	background: transparent;
	margin: 0px 5px;
}
.drawBoxrighticoms button:hover, .drawBoxrighticoms button:focus {
	color: #6476f8;
}
.snroundvalue {
	position: relative;
}
.snroundvalue svg {
	height: 40px;
}
.snroundvalue {
    position: relative;
}
.snroundvalue .valuen{
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-weight: 800;
    font-size:28px;
}
.customBall {
    width: 60px;
    height: 60px;
    background: #ad4190;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
        border: 1px solid#ad4190;
}
.customBall:before {
    position: absolute;
    content: '';
    height: 95%;
    width: 95%;
    left: 0px;
    top: 0px;
    border-radius: 65px;
    background: #c148a0;
}
.customBall:after {
    position: absolute;
    content: '';
    height: 90%;
    width: 90%;
    left: 0px;
    top: 0px;
    border-radius: 65px;
    background: #d750b2;
}
.customBall>div{
    position: absolute;
    height: 20px;
    width: 26px;
    background: #fff;
    z-index: 1;
    border-radius: 65px;
    transform: rotate(-40deg);
    top: -5px;
    left: -5px;
}
.labeltag {
    display: inline-block;
    background: #6476f8;
    color: #fff;
    padding: 6px 20px;
    border-radius: 15px 5px 15px 15px;
    font-size: 14px;
}
.roundsSection .hideshow {
    background: #fff;
}
.secured .stepSn {
    background: #ecefff;
    padding: 15px;
    border-radius: 30px 30px 15px;
    border: 1px solid #dbe0fa;
}
.secured .stepSn .stepnumber {
    width: 120px;
    height: 120px;
    line-height: 28px;
    padding: 1rem;
    position: relative;
    z-index: 2;
    background-color: #e3ddfc;
    animation: Secured_border-transform__Nr3mB 10s linear infinite alternate forwards;
    text-transform: uppercase;
    font-weight: 600;
    margin: auto;
    font-size: 18px;
    padding-top: 29px;
}
.secured .stepSn .stepnumber span {
    font-size: 34px;
    display: block;
}
.secured .row>div:nth-child(2) .stepSn .stepnumber {
    background-color: #7110ff87 !important;
}
.secured .row>div:nth-child(3) .stepSn .stepnumber {
    background-color: #c7e5ff !important;
}
.secured  .stepSn h5 {
    margin-top: 1rem;
    text-align: center;
        color: #6476f8;
    font-weight: 700;
    font-size: 24px;
}
.secured  .stepSn p {
    text-align: center;
    color: #131740;
    font-weight: 700;
    font-size: 16px;
}
.winingSection   h2 {
    margin-top: 0rem !important;
    color: #6476f8;
    font-weight: 700;
    font-size: 28px !important;
}
.winingSection   h4 {
    margin-top: 0rem !important;
    font-weight: 700;
    font-size: 20px;
}
.winingSection p,
.winingSection li {
    margin-top: 0rem !important;
    font-size: 15px !important;
    font-weight: 600;
    color: #212529b3;

}
.winingSection li {
    margin-bottom: 10px;
}
.winingBox {
    padding: 15px;
    background: #ecefff;
    padding: 15px;
    border-radius: 30px 30px 15px;
    border: 1px solid #dbe0fa;
    border-bottom: 3px solid #dbe0fa;
    width: auto;
    display: inline-block;
}
.topwin {
    padding-left: 40px;
}
.topwin .customBall {
    width: 30px;
    height: 30px;
}
.topwin .snroundvalue .valuen {
    line-height: 30px;
    font-size: 14px;
}
.topwin .customBall>div {
    transform: rotate(-40deg);
    top: -5px;
    left: -8px;
    height: 12px;
    width: 22px;
}
.checkWin>div  {
    padding: 3px 12px;
}
.checkWin i  {
    padding: 0px 12px;
    font-size: 18px;
}

.checkWin i.fa-check-circle-o  {
    color: rgb(49, 208, 170);
}
.checkWin i.fa-times {
    color: rgb(237, 75, 158);
}
.checkWin.win>div {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: #6476f8;
}

.checkWin.win:first-child>div    {
    border-left: 2px solid ;

    border-color: #6476f8;
}
.checkWin.win.last-child>div    {
    border-right: 2px solid ;
        border-color: #6476f8;
}
.checkWin.disable>div {

        border-color: #d1d9ff !important;
}
.winingBox2 {
    width: 100%;
}
.midwin h6 {
    font-weight: 600;
    font-size: 13px;
}
.winingBox2 p{
    margin-bottom: 3px;
}
.winingBox2 .colorbox {
    display: inline-block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-color: rgb(255, 227, 98);
}
.darkcoolor p{
    color: #6476f8;
    font-weight: 700;
}
.displayno.mainMidal, .displayno.mainMidal>* {
    height: 0px;
    padding: 0px;
    font-size: 0px;
    margin: 0px;
    opacity: 0;
}
.displayyes+.hideshow i{
    transform: rotate(180deg);
}
.snvalue {
    padding: 15px;
        background: #ecefff;
    padding: 15px;
    border-radius: 15px 15px 15px;
    border: 1px solid #dbe0fa;
    margin-bottom: 15px;
}
.snticket {
  background: url(../../Assets/lottery/luckydraw.png);
  background-repeat: no-repeat;

  background-size: cover;
  border-radius:10px;
  border-radius:10px;
  padding: 30px 20px;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
}
.snticket:before {
    position: absolute;
    content: '';
    left: -80px;
    top: -11px;
    width: 100%;
    height: 180%;
    background: #f4c795f0;
    transform: rotate(-15deg);
}
.row>div:nth-child(2) .snticket:before {

}
.snticket h2, .snticket h3 {
    margin: 0px !important;
}
.leftticket  {
    position: relative;
}
.ticketSection .snticket h2 {
    font-weight: 700;
    font-size: 20px !important;
}
.snticket h3 {
    font-size: 20px;
    background: #fff;
    padding: 6px 10px;
    margin-top: 10px !important;
}
.ReactModal__Overlay  {
    background-color:rgb(0 0 0 / 21%) !important;
}

.snWallet {
    width: 100%;
    background: #dddddd0f;
    border: 1px solid #ffffff0d;
    border-radius: 7px;
    padding: 6px 12px;
    margin-top: 10px;
}
.snWallet img {
    width: 45px;
    order: 2;
}
.snWallet h4 {
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    display: flex;
    gap: 10px;
}
.snWallet h4 .labelspan {
    background: #b5b5b51f;
    border: 1px solid #ffffff0d;
    border-radius: 7px;
    padding: 4px 10px;
    font-size: 13px;
}
.modalBody {
    padding: 45px 15px;
    
}
.modalBody label{
    font-weight: 600;
    margin-bottom: 5px;
}
.inPut{
    background: #ecefff;
    padding: 10px 15px;
    border-radius: 15px;
    border: 1px solid #dbe0fa;
    margin-bottom: 10px;
}
.inPut p {
    margin: 0px;
    text-align: right;
}
.inPut input {
    background: transparent;
    border: 0px solid #dfe3f5;
    padding: 0px 5px !important;
    text-align: right;
    font-weight: 800;
    font-size: 23px;
    margin-bottom: 5px;
    height: 33px;
    border-radius: 0px;
}
.inPut input.form-control:focus {
    color: #212529;
    background-color: #f4f6ff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}
.modalHeader {
    padding: 15px;
    background: #e9e9e9;
    padding: 10px 15px;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
}
.modalHeader h2{
    margin-bottom: 0px; 
    font-weight: 700;
}
.modalHeader button {
    background: #bbb6b630;
    border: 0px;
    color: #000;
    height: 32px;
    line-height: 14px;
    width: 32px;
    border-radius: 4px;
    font-size: 22px;
    text-align: center;
    padding: 0px;
    display: block;
}
.popupcenter {
    width: 100%;
    max-width: 520px;
    margin: auto;
}
.popupcenter h2 {
    font-size: 22px;
}
.snWallet:hover, .snWallet:focus {
    background: #dddddd24;
}
.ReactModal__Content {
    border: 0px !important;
    width: 350px !important;
    padding: 0px !important;
    background: transparent !important;
    border-radius: 00px !important;

}
.ReactModal__Content .popupcenter{
    background: #fff;
    overflow: hidden;
        border: 1px solid rgb(204, 204, 204);
    border-radius: 20px !important;
}
.valuemodal{
    margin-top: 5px;
}
.valuemodal p {
    margin-bottom: 0px;
    font-weight: 500;
}
.popupcenter button.btn {
    margin-top: 15px;
    width: 100%;
}
.ReactModal__Content .popupcenter{
    background: #fff;
}

body.bodydarkMode  .roundsSection:before, body.bodydarkMode  .looterpage:before {
    
    opacity: 0.5;
    background-image: url("../../../public/hero_dark_bg.png");
    opacity: 0;
}
@media (max-width: 767px) {
    .buybtnouter {
        padding: 7px 10px;
    }
    .looterpage.sn_page_pad   h1 {
        font-size: 30px !important;
    }
    .looterpage.sn_page_pad h3 {
        margin-top: 0px;
        font-size: 18px !important;
    }
    .ticketSection h2 {
        font-size: 23px !important;
    }   
    .drawBox {
        width: 100%;
    }
    .ReactModal__Content {
        width: 310px !important;
    }
    .snvalue {
        margin-top: 0px;
    }
}
.warning_note {
    width: 100%;
    padding-left: 0.5rem;
    text-align: left;
    position: relative;
    font-size: 14px;
    color: "red";
    top: -5px;
    margin: 0;
}
.lightsnvalue {
    background: #dde2ff;
}
.lightsnvalue.snvalue p {
    font-size: 17px !important;
    font-weight: 700;
}

.looterpage:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 0100%;
    opacity:1;
    background-image: url(../../../public/lottery/diwali.png);
    background-size: cover;
    background-position: top;
    left: 0px;
    top: 0px;
    background-attachment: inherit;
}
.diwali1{
        position: absolute;
        width: 10%;
        top: 0px;
        left: 0px;
}
.diwali2, .firefixed{
        position: absolute;
        width: 10%;
        top: 0px;
        right: 0px;
}
.firefixed {
    position: fixed;
    top: auto;
    bottom: 0px;
        z-index: 1;
}
.firefixed2 {
    right: auto;
    left: 0px;
    bottom: 0px;
}
.diya {
    height: 90px;
    position: relative;
    top: -25px;
    margin: 0px 8px;
}
.drawHead {
    background: #ffca4e;
}
.drawBox {
    background: #ffdc97 !important;
}


.looterpage h1{
  /*-webkit-animation: text-animation 5s linear infinite alternate;
          animation: text-animation 5s linear infinite alternate;*/
          color: #000;
          position: relative;
}

.snvalue {
    background: #fff9ea;
    border: 1px solid #ffca4e;
}
.snvalue h2 {
    color: #cd940c;
}

.p-relative {
    position: relative;
}

@keyframes text-animation {
  from {
    color: #ff564e;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
  }
  to {
    color: #ff564e;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff,  0 0 30px #ff0080, 0 0 40px #ff0080, 0 0 55px #ff0080, 0 0 75px #ff0080;
    text-align: center;
  }
}
@media (max-width: 767px) {
    .snWallet img {
        width: 35px;
    }
    .diya {
        height: 30px;
        top: 0px;
        display: block;
        margin: 5px auto ;
    }
}