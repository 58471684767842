@import "../../../Global/Variables.scss";

.pers_d,.pers_l{
  //background-image: url("../../../Assets/hero_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  nav{
    background-color: $light;
  }

  ._pers {
    min-height: 700px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      color: $link;
      width: 100%;
    }
    h4{
        font-size: 18px;
        width: 100%;
        color: $gray;
        font-weight: 500;
    }
   
    form {
      background-color: $formp_l;
      box-shadow: 0 15px 30px rgb(85 85 85 / 40%);
      padding: 2rem;
      border-radius: 1rem;
      position: relative;
      z-index: 2;
      width: 100%;
      .profileImageWrapper {
        position: relative;
        display: inline-block;
      }
      
      .rankuser {
        display: block;
        margin: 0 auto;
        width: 150px; /* Adjust size as needed */
        height: 150px;
        border-radius: 50%; /* Make it circular */
        object-fit: cover;
      }
      
      .editIconWrapper {
        position: absolute;
        bottom: 10px;
        left: 35%;
        cursor: pointer;
      }
      
      .editIcon {
        width: 50px;
        height: 50px;
        font-size: 21px;
        border-radius: 100px;
        line-height: 50px;
        background: #ffffff90;
        color:#000000;
      }
      // .editIcon {
      //   font-size: 24px; /* Adjust size as needed */
      //   color: #ae1e1e; /* You can choose a different color */
      //   background-color: #00000053; /* Optional: Add background for better visibility */
      //   padding: 5px;
      //   border-radius: 50%; /* To make the icon circular */
      // }
      
      .fileInput {
        display: none;
      }
      .warning_note,.phoneWarning , .warning_note_temp , .warning_note_dob {
        width: 100%;
        padding-left: 0.5rem;
        text-align: left;
        position: relative;
        font-size: 14px;
        color: $neg;
        top: -5px;
        margin: 0;
      }
      .phoneWarning{
        display: none;
      }
      .warning_note_dob{
        width: 50%;
        margin-left: 50%;
      }

      .cheks{
        label{
            cursor: pointer;
            margin-right: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: $gray;
        }
        input{
            margin-right: 1rem;
            cursor: pointer;
        }
      }

      .oninput {
        width: 100%;
        input{
            width: 99%;
        }
      }
      .twoinput , .threeinput, .fourinput{
        width: 100%;
        input{
            width: 49%;
        }
      }

      .fourinput{
        width: 100%;
        margin-top: 0.5rem;
        input{
          width: 49%;
        }

        .sbox {
          padding: .7rem;
          background-color: $light;
          border-radius: 0.5rem;
          width: calc( 49% / 3);
  
          select{
            width: 100%;
            background-color: transparent;
            border: inherit;
            outline: none;
            font-size: 14px;
          }
        }
      }

      .threeinput{
        input{
            width: calc( (100% / 3) - 5px );
        }
        margin-top: .5rem;

        .sbox {
          padding: .7rem;
          background-color: $light;
          border-radius: 0.5rem;
          width: calc( 95% / 3);
  
          select{
            width: 100%;
            background-color: transparent;
            border: inherit;
            outline: none;
            font-size: 14px;
          }
        }
      }

      input, select {
        width: 100%;
        background-color: $light;
        color: $gray;
        font-size: 14px;
        padding: 0.8rem;
        border: none;
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        outline: none;
        &::placeholder {
          color: $l_ph;
        }
      }
      

      .sc {
        width: 100%;
        .sbox {
          width: 49%;
        }
        input {
          width: 49%;
        }
      }

      .dragBoxes{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4 , 1fr);
        grid-gap: 1rem;

        h5{
            font-size: 16px;
            margin-top: 1.5rem;
            font-weight: 500;
            color: $gray;
        }

        .dragbox{
            width: 100%;
            height: 200px;
            background-color: $light;
            border-radius: .5rem;
            border: 2px dashed $l_ph;
            margin: 1rem 0;
        }
      }
 
    }

      .pers_btn {
        margin-top: 1rem;
        border-radius: 2rem;
      }
    }

    .bgi {
        width: 600px;
        position: absolute;
        z-index: 1;
        transform: translateX(-50%);
        left: 50%;
      }
    }


// ******* Dark mode ********

.pers_d{
  background-image: url("../../../Assets/hero_dark_bg.png");
  nav{
    background-color: $register_nav;
  }
  ._pers {
    form {
      background-color: $formp_d;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.273);
        label{
            color: $light !important;
        }
      h1,
      .terms {
        color: $light;
      }
      h4 , h5{
        color: $third !important;
      }
    }
  }
}


// ******** Responsive **********

@media screen and (max-width : 930px ) {
  .pers_d,.pers_l{
    form{
      .warning_note_dob{
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
     .fourinput{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 1rem ;
      input{
        width: 100% !important;
        grid-column: 1 / span 3;
      }
      .sbox{
        width: 100% !important;
        margin: .5rem 0;
      }
    }
    .bgi{
      width: 70%;
    }
  }
}

@media screen and (max-width : 500px ) {
  .pers_d,.pers_l{
    ._pers{
    min-height: 1000px;
    }
    .phoneWarning{
      display: block !important;
    }
    .warning_note_temp{
      display: none;
    }
    .twoinput , .threeinput, .fourinput{
      display: flex;
      flex-direction: column;
      input,.sbox{
        width: 100% !important;
      }
      .sbox{
        margin: .5rem 0;
      }
    }
    .bgi{
      width: 90%;
    }
  }
}
