@import '../../../Global/Variables.scss';

.rank_l{
    background-color: $light;
      // -------- modified 
    //   nav{

    //   }
    //   nav > div:first-child , footer > div:first-child{

    //     justify-content: space-between;
    // }
    
        

       

        .rank_wrapper{
            padding: 1rem;
            overflow: auto;

            table{
                min-width: 1350px !important;
            }

            thead, tbody, tr {
                display: table;
                width: 100%;
                table-layout: fixed;
              }
              thead{
                background-color: #e1e8f64a;
                padding: 0 .5rem;
                border-radius: inherit;
              }
              
              tbody {
                display: block;
                overflow: auto;
                table-layout: fixed;
                max-height: 700px;
                min-height: 500px;
                // max-height: calc( 100vh - 200px);
              }
            
              table{
                width: 100%;

                padding: .5rem;
                border-radius: .5rem;
                th{
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                    color: $link;
                    padding: 10px 10px;
                    &:first-child{

                    }
                }
                tr{
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray;
                    border-spacing: 0 .2rem;
                    td{
                        padding:10px;
                        border-bottom: .1px solid $l_ph;
                    }
                    .first,.second,.third{
                        span{
                            padding: .2rem .5rem;
                            position: relative;
                            border-radius: .5rem;
                            color: $link;
                            font-size:14px;
                        }
                    }
                    .first span{
                        background-color: $first;
                    }
                    .second span{
                        background-color: $second;
                    }
                    .third span{
                        background-color: $third;
                    }
                }
              }
        }
}

// ********** Dark Mode *****

.rank_d{

    background-color: $cb_d;
    nav{
        background-color: $register_d;
    }
    .tabs{
        button{
            background-color: #23212e;
            color: #fff;
        }
        .active{
            background-color: $register_d;
            color: $light;
        }
    }
    table{
        background-color: $cb_d !important;
        border: .1px solid $ex_border_d;

        thead{
            background-color: $fees_d_h !important;
            th{
                color: $light !important;
            }
        }

        td{
            border-color: $ex_border_d !important;
            color: $third;
        }
    }
}



// ********** Responsive************

@media screen and (max-width:1382px){
.rank_d,.rank_l{
    .note{
        display: block;
    }
}
}
