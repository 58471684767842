@import "../../../Global/Variables.scss";

.umm_d,.umm_l{
    nav{
      }

      .umm{
        height: 700px;
        h1{
            font-size: 50px;
        }
        img{
            width: 500px;
            margin-top: 1rem;
        }
      }
}


// ***** Dark mode ******

.umm_d{
    nav{
    }
    .umm{
    }
}


// ***** Dark mode ******

.umm_d{
    nav{
    }
    p{
        a{
        }
    }
    .umm{
    }
}


// ******** Responsive *********

@media screen and (max-width:670px){
    .umm_d,.umm_l{
        .umm{
            h1{
                font-size: 40px;
            }
            img{
                width: 80%;
            }
        }
    }
}

@media screen and (max-width:550px){
    .umm_d,.umm_l{
        .umm{
            h1{
                font-size: 30px;
            }

        }
    }
}

@media screen and (max-width:400px){
    .umm_d,.umm_l{
        .umm{
            height: 500px;
            h1{
                font-size: 24px;
            }
        }
    }
}




