@import "../../Global/Variables.scss";

.deposit_d,
.deposit_l {
  width: 100%;
  max-height: 80vh;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 1rem;
  padding-bottom:30px;
  display: flex;
  .details,
  .form {
    width: 50%;
    h2 {
      font-size: 16px;
      font-weight: 500;
      color: $gray;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .details {
    padding-right: 0.5rem;
    // border-right: .5px solid $ex_border;
    .info {
      border-bottom: 1px dashed $gray;
      margin: 0.5rem 0;
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
      p {
        color: $gray;
        margin-bottom: 0.5rem;
        button {
          background: none;
          outline: none;
          border: none;
          font-size: 18px;
          position: relative;
          transition: $t_normal;

          &:hover {
            color: $btnbg;
          }

          span {
            color: $light;
            font-size: 14px;
            background-color: $gray;
            padding: 0.1rem 0.3rem;
            border-radius: 0.2rem;
            position: absolute;
            bottom: -30px;
            right: -25%;
            width: fit-content;
          }
        }
      }
    }
    h3 {
      margin-bottom: 0.3rem;
      margin-top: 1rem;
      color: $fail !important;
    }
    P {
      font-size: 14px;
      color: $gray;
    }
  }

  ul {
    padding-left: 1rem;
    li {
      text-align: justify;
    }
  }

  .form {
    padding-left: 0.5rem;
    // border-left: .5px solid $ex_border;
    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        width: 70%;
        padding: 0.5rem;
        background-color: $ex_border;
        border: 2px solid $light;
        font-weight: 500;
      }
      button:nth-child(2) {
        width: 30%;
      }
      button.active {
        background-color: $btnbg;
        color: $light;
      }
    }

    .qrbox {
      width: 80%;
      // height: 350px;
      // border: 1px solid $ex_border;
      margin: 0 auto;
      margin-top: 1rem;
      border-radius: 0.5rem;
      position: relative;

      span {
        position: absolute;
        top: 85%;
      }

      .qr {
        width: 100%;
        background-color: $link;
        margin-bottom: 0.5rem;
      }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 1rem;

      .warning_note {
        width: 100%;
        padding-left: 0.5rem;
        text-align: left;
        position: relative;
        font-size: 14px;
        color: $neg;
        top: -5px;
        margin: 0;
      }

      input,
      button,
      .dragdrop {
        margin: 0.5rem 0;
        width: 100%;
        padding: 0.5rem;
        font-size: 14px;
        border: 1px solid $ex_border;
        outline: none;
      }

      .dragdrop {
        position: relative;
        text-align: center;
        height: 60px;
        cursor: copy;
        padding: 0;
        p{
          span{
            color: $fail;
          }
        }
      }

      button {
        color: $light;
        background-color: $bb3;
        border: none;
        outline: none;
        border-radius: 0.3rem;
        font-weight: 500;
      }
    }
  }
}

// ************ Dark mode *************

.deposit_d {
  .details,
  .form {
    h2 {
      color: $light;
    }
    .options {
      button {
        border-color: $register_d;
      }
    }
  }
  .details > * {
    color: $light;
  }
  .details {
    .info {
      p {
        color: $ex_border;
      }
      button {
        color: $light;
      }
    }
  }
}

// ********** Responsive ***********

@media screen and (max-width: 795px) {
  .deposit_d,
  .deposit_l {
    flex-direction: column;
    .details,
    .form {
      width: 100%;
    }
    .form {
      margin-top: 2rem;
      form {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .deposit_d,
  .deposit_l {
    .form {
      .qrbox {
        width: 100%;
      }
      .options {
        button {
          font-size: 10px;
        }
      }
    }
  }
}

// ******** for all ********

.depositall_d,
.depositall_l {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-height: 80vh;
    overflow: auto;

    .dirqr{
        width: 100%;
        padding: 1rem 2rem;
        overflow-x: hidden;
        .destination{
          margin: .5rem 0;
        }
        @media screen and (max-width : 835px) {
            padding: 1rem ;
        }
        @media screen and (max-width : 780px) {
            flex-direction: column;
            // padding-top: 10rem;
        }
    }

    .qrcode{
        width: 40%;
        margin-right: 1rem;
        span{
            margin: 1rem 0;
            color: $link;
            font-size: 18px;
            font-weight: 600;
        }
    }
    h2{
        font-size: 20px;
        color: $link;
        font-weight: 600;
        margin-bottom: 1rem;
                @media screen and (max-width : 780px) {
                    margin-top: 2rem;
        }
    }
    .address{
        border: 1px solid $ex_border;
        padding: .3rem 1rem;
        width: 90%;
        @media screen and (max-width : 500px) {
            width: 80vw;
        }
        span{
            overflow: auto;
        }
        button {
            background: none;
            outline: none;
            border: none;
            font-size: 18px;
            position: relative;
            transition: $t_normal;
            margin-left: 1rem;
  
            &:hover {
              color: $btnbg;
            }
  
            span {
              color: $light;
              font-size: 14px;
              background-color: $gray;
              padding: 0.1rem 0.3rem;
              border-radius: 0.2rem;
              position: absolute;
              bottom: -30px;
              right: -25%;
              width: fit-content;
            }
          }
    }
    .impn{
        background-color: $impn;
        margin-top: 2rem;
        padding: 1rem;
        width: 90%;
        border-radius: .5rem;

        h3{
            margin-bottom: .5rem;
        }
    }
  h1 {
    color: $link !important;

  }
  .dbox{


    cursor: pointer;
    width: 100%;
    padding: .5rem 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    .chainOpt{
        cursor: pointer;
        width: 100%;
        outline: none;
        font-size: 18px;
        border: none;
      }
  }

}

// ******** dark mode ******

.depositall_d{
    h1{
        color: $light !important;
    }
    .dbox{
        background: $light; 
        color: $link;
    }
    .address , .address button{
        color: $light;
    }
    .dbox{
        background-color: $light;
    }
    h2 , p{ 
        color: $light;
        button{
            color: $light;
        }
    }
    .qrcode{
        span{
            color: $light;
        }
    }
}