.wIcon svg {
    color: #fff !important;
}
.navbarMain {
    overflow-x: initial;
} 

@media (min-width: 1920px) {
    .w-20{width: 20%;}
}
@media (min-width: 1400px) {

    .navbarMain .container>div.bg:before {
            width: 98%;
    }
    .fade1 {
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }
    .scrollFade--visible .fade1{
      opacity: 1;
    }

    .fade2 {
      transition-delay: 10s;
      transition: opacity 0.8s ease-in-out;
      opacity: 0;
    }
    .scrollFade--visible .fade2{
      opacity: 1;
    }
    .fade3 {
      transition-delay: 3s;
      transition: opacity 1.2s ease-in-out;
      opacity: 0;
    }
    .scrollFade--visible .fade3{
      opacity: 1;
    }
    .fade4 {
      transition-delay: 4s;
      transition: opacity 1.5s ease-in-out;
      opacity: 0;
    }
    .scrollFade--visible .fade4{
      opacity: 1;
    }
    .fade5 {
      transition-delay: 5s;
      transition: opacity 1.8s ease-in-out;
      opacity: 0;
    }
    .scrollFade--visible .fade5{
      opacity: 1;
    }
    .boxanimation1 {
      transform: translateX(-50px);
      transition: all 1.2s ease-in-out;
      opacity: 0;
    }
    .boxanimation2 {
      transform: translateY(-50px);
      transition: all 1.2s ease-in-out;
      opacity: 0;
    }
    .boxanimation3 {
      transform: translateX(50px);
      transition: all 1.2s ease-in-out;
      opacity: 0;
    }
    .boxanimation4 {
      transform: translateX(-50px);
      transition: all 1.5s ease-in-out;
      opacity: 0;
    }
    .boxanimation5 {
      transform: translateY(-50px);
      transition: all 1.5s ease-in-out;
      opacity: 0;
    }
    .boxanimation6 {
      transform: translateX(50px);
      transition: all 1.5s ease-in-out;
      opacity: 0;
    }

    .scrollFade--visible .boxanimation1 {
        transform: translateX(0%);
        opacity: 1;
    }
    .scrollFade--visible .boxanimation2 {
        transform: translateY(0%);
        opacity: 1;
    }
    .scrollFade--visible .boxanimation3 {
        transform: translateX(0%);
        opacity: 1;
    }

    .scrollFade--visible .boxanimation4 {
        transform: translateX(0%);
        opacity: 1;
    }
    .scrollFade--visible .boxanimation5 {
        transform: translateY(0%);
        opacity: 1;
    }
    .scrollFade--visible .boxanimation6 {
        transform: translateX(0%);
        opacity: 1;
    }

    .snBox:hover {
        transform: scale(1.1);
    }
    .snBox:hover .snBoxImage {
        transform: translateY(-10%);
        width: 120px;
    }
    .snBox:hover h4 {
      padding-bottom: 5px;
    }
    
}

@media (max-width: 1500px) {
    .h100vh {
        height: 100vh;
        padding-top: 15vh;
    }
    
    .bodydarkMode [class*='Navbar_menubar'] span {
        background-color: #eee !important;
    }
}
@media (max-width: 1350px) {
    .btnslabel .snbtnlabel>div {
        padding: 10px 8px;
    }
    .h100vh {
        height: 100vh;
        padding-top: 0vh;
    }
    .sliderHero h1 {
        width: 80%;
    }
    .snBox h4 {
        font-size: 27px;
    }
    .snBox  h3 {
        font-size: 19px;
    }
    .darkLightBtn button{
        margin-left: 0px !important;
    }
    .listingMulti {
        width: 100%;
    }
    section{
        overflow: hidden;
        overflow-y:auto;
    }
    .newListing:after {
        top:  auto;
        bottom: 100px;
    }
}
@media (max-width: 991px) {

    .dropMenuIn  button {
        margin-left: 0px !important;
    }
    .my_col_mobile {
        width: 90%;
    }
    .signPage {
        overflow: hidden;
    }
    .cusHero>*, .ligtMode.signPage>*, .darkMode.signPage>* {
        width: auto !important;
        height: auto !important;
    }
    .locktag {
        margin-top: 10px;
    }
    .container, .container-md, .container-sm {
        max-width: 90%;
    }
    .padSec, .padSecimporant {
        padding-top: 40px  !important;
        padding-bottom: 40px  !important;
    }
    .h100vh {
        height: auto;
        padding-top: 100px !important;
        padding-bottom: 50px !important;
    }
    .sliderHero h1 {
        font-size: 36px;
    }

    .padtop0Sec {
        padding-top: 50px;
    }
    .navbarMain .container {
        max-width: 95%;
    }
    .navbarMain  button.btn, .navbarMain  button.btns, .navbarMain  a.btns, .navbarMain  a.btn, .navbarMain  .inputHandle>button {
        padding: 8px 12px;
    }
    .sliderHero h1, .appbannerIn {
        width: 100%;
    }
    [class*='OpenOrder_']  .table-responsive {
        overflow-x: scroll !important;
    }
    .latestdesigntable, [class*='OpenOrder_'] .table-responsive>table  {
        width: 700px !important;
    }
    html body [class*='ContactUs_contact_'] [class*='ContactUs_content__'] [class*='ContactUs_texts'] {
            transform: translateY(0%) !important;
    }
    .sn_page_pad h1 {
        font-size: 29px;
    }
    .ExchangePage .latesthandle[class*='SetOrder_so'] {
        padding: 15px 15px !important;
    }
    .buysellForm  {
        height: auto !important;
    }
    .allboxesmini  {
        grid-template-columns: repeat(3, 1fr);
        display: grid;
    }
    
    .custableresponsive table {
       /*  width: 1160px; */
    }
    .popHandle>div.popHandleIn {
        min-width: 95% !important;
    }
}
@media (max-width: 750px) {
    .container, .container-md, .container-sm {
        max-width: 100%;
    }
    .snBox h3 {
        font-size: 16px;
    }
    .snBox h4 {
        font-size: 20px;
    }
    .sliderHero h1, .titleh2, .appbannerIn h2, .stakeMain h1 {
        font-size: 26px !important;

    }
    .swal-text {
        font-size: 20px;
    }
    .tbalehead .inputHandle>.flex-between {
        padding: 0px 0px;
    }
    .popHandleIn input+button, .popHandleIn input+button+button {
        width: 147px !important;
        margin-left: 2px !important ;
        padding: 0px 5px !important; 
    }
    .vaolumtext {
        font-size: 12px !important;
    }
    .navbarMain .container>div.bg {
            width: 96%;
    }
    .mydaterangepicker input{
      margin-top:10px !important;
    }
    .bannerIn a.custombtn, .bannerIn a.custombtn1{
        width: 100%;
        position: relative;
        display: block;
    }
    .snRank {
        width: 300px;
    }
    .rankInfo:after, .rankInfo:before {
        transform: rotate(-24deg);
        left: -150px;
        top: 110px;
    }
    .rankInfo:before {
        left: -160px;
    }
    .logoRank {
        padding-top: 60px;
        width: 120px;
    }
    .RankPerInfo {
        padding: 30px 20px;
    }
    .RankPerInfo p {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .tableLook>div{
      width: 50%;
    }
    .tableLook>div:last-child{
      text-align: left !important;
    }
    .thLook{
        display: none !important;
    }
    .mobShow {
      display: block;
    } 
    .tdLook .usertd {
        width: 100% !important;
    }
    
    .tdLook {
      align-items: flex-start !important;
    }
    .rankuserImgLatest {
      margin-top: 4px;
    }
}

@media (max-width: 420px) {
    .allboxesmini  {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
    }
}
