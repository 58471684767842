@import "../../../Global/Variables.scss";

.nf_d,.nf_l{
    nav{
      }

      .nf{
        height: 700px ; 
        h1{
            font-size: 65px;
        }
        p{
        }
        img{
            width: 100%;
            margin-top: 3rem;
        }
      }
}


// ***** Dark mode ******

.nf_d{
    nav{
    }
    p{
        a{
        }
    }
    .nf{
    }
}

// ******** Responsive *********

@media screen and (max-width:550px){
    .nf_d,.nf_l{
        .nf{
            height: 600px;
            h1{
                font-size: 35px;
            }
            p{
                font-size: 14px;
            }
            img{
                width: 90%;
            }
        }
    }
}

@media screen and (max-width:400px){
    .nf_d,.nf_l{
        .nf{
            height: 500px;
            h1{
                font-size: 28px;
            }
            p{
                font-size: 12px;
            }
        }
    }
}


