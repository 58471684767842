@import "../../../Global/Variables.scss";

.page_d , .page_l{
    display: flex;
    margin: 2rem;
    margin-left: 0;
    button{
        background-color: $btnbg1;
        border: none;
        padding: .3rem .8rem;
        margin-left: 1rem;
        font-size: 12px;
        color: $l_ph;
        border-radius: .2rem;
        font-weight: 600;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
    }
    button.active{
        color: $btnbg;
    }
}


// ***** Responsive *******

@media screen and (max-width : 425px) {
    .page_d , .page_l{
        width: 100% ;
        display: flex;
        justify-content: space-around;
        button{
            margin: 0;
        }
    }
}

@media screen and (max-width : 400px) {
    .page_d , .page_l{
        width: 100% ;
        display: flex;
        justify-content: space-around;
        button{
            font-size: 9px;
            font-weight: 400;
            margin: 0;
            padding: .25rem .4rem;
        }
    }
}

