@import '../../Global/Variables.scss';

.dandd{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $light;
    border-radius: inherit;
    z-index: 2;

    &:hover{
        .onHover{
            display: block;
        }
    }

    label{
        min-width: inherit !important;
        max-width: inherit !important;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;
        div,svg{
            display: none;
        }
        border: none;
    }
    img{
        position: absolute;
        object-fit: cover;
        height: inherit;
        top: 0;
        left: 0;
        width: inherit;
        border-radius: inherit;
    }
    p{
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        min-width: 90%;
        font-size: 14px;
        z-index: 1;
        color: dodgerblue;
        background-color: $light;
        padding: .5rem;
        border-radius: .5rem;
        span{
            color: crimson;
        }
    }
    .onHover{
        display: none;
    }
}