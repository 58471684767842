$btnbg : #ffac47;
$btnbg1 : #e8e0ff;
$btn1color : #826ebb;
$btnshadow : 0 0 transparent, 0 0 #0000, 0 0 transparent, 0 0 #0000, 5px 5px 10px rgb(26 56 142 / 28%), inset 2px 2px 6px #b79dff, inset -5px -5px 10px #6240b9 ;
$btn1shadow: 0 0 transparent, 0 0 #0000, 0 0 transparent, 0 0 #0000, 5px 5px 10px rgb(173 182 211 / 33%), inset 2px 2px 6px #e6deff, inset -3px -3px 5px #8e94ab;
$active_link : #ffaa02;
$active_link_n : #ffaa02;
$link : #2f2f2f;
$active_link_d : #ffaa02;
$l_mode : #4f5b78;
$lighticon : #fff;
$light : #fff;
$bborder : #f7941d4a;
$iborder : lightblue;
$iborder1 : #44a5c6;
$bgtext : #ffac47;
$bgtext1 : #52c7ed;
$securedlbg : #ecefff;
$secureddbg : #1e1c29;
$securedd_c_bg : #1b1927;
$securedl_c_bg : #fcfcff;
$gray : #454545;
$movingshape : #e3ddfc;
$buysell_l : #fff;
$footer_l : #f9f9f9;
$footer_d : #1c1a29;
$copyright_l : #ebebeb;
$copyright_d : #201e2f;
$ex_bg : #F9F9F9;
$ex_border : #e3e3e3;
$ex_border_d : #363349;
$ex_grid_d : #23212d;
$ex_search_dbg : #2A283B;
$ex_th_l : #909AB2;
$register_d : #1c1a29;
$register_nav : #181722;
$register_nav_l : #f3f7ff;

$insidebox1 : #D3E9F8;
$p_iconbg1 : #B7DBF2;
$bb1 : #5BA0CF;
$insidebox2 : #F3CFE2;
$p_iconbg2 : #EEB7D0;
$bb2 : #C76F9C;
$insidebox3 : #CDF8D1;
$p_iconbg3 : #A7ECAB;
$bb3 : #549D54;
$neg : crimson;

$so_bb_l_a : #a3f0c9;
$so_bb_l : #d7f6e7;
$so_sb_l : #ffe5e5;
$so_sb_l_a : #ef5350;
$impn : #ffaaa98b;
$l_ph : #8e8e8e;
$tmb_bg_l : #e9e9e9;
$form_l : #b3b3b361;
$r_form_l : #b3b3b361;
$r_form_d: #181722;
$form_d : #04001d3f;

$ci_l : #EFF3FD;
$ci_d : #181722;
$cib_l : #D6E1F9;
$cib_d : #1c1a29;
$cb_l : #FDFEFE;
$cb_d : #1e1c29;
$ct_d : #222032;
$cmh_d: #DDE0FC;
$fees_l : #F5F8FE;
$fees_l_h : #E1E8F6;
$fees_d_h : #1c1a29;
$first : #CDC0F9;
$second : #F3E0A5;
$third : #D3E9F8;
$nbc_l : #E8EFFD;
$nbc_d : #1e1c2d;
$nb_d : #1e1c29;
$umt: #4DA795;
$fail: #E48751;
$formp_l : rgb(242 242 242 / 74%);
$formp_d : #2c1d4de1;
$depo : #63B7F9;
$impnp1_l : #E7E0FD;
$impnp2_l : #C7E4FC;
$p2 : #0095ff;


$t-normal : all .3s ease-in-out;