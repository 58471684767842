@import "../../../Global/Variables.scss";

.bankdetials_d,
.bankdetials_l {
  background-image: url("../../../Assets/hero_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .warning_note , .warning_note2{
    width: 100%;
    padding: 0 1rem;
    text-align: left;
    position: relative;
    font-size: 14px;
    color: $neg;
    transform: translateY(-7px);
    background-color: $insidebox2;
    z-index: 9;
    border-radius: 0.5rem;
    margin: 0;
  }
  .warning_note2{
    position: relative;
    top: -7px;
  }

  nav {
    background-color: $light;
  }

  ._bankdetials {
  
    position: relative;

    h1 {
      font-size: 30px;
      font-weight: 600;


      width: 100%;
    }
    h4 {
      font-size: 18px;
      width: 100%;

      color: $gray;
      font-weight: 500;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 14px;
      font-weight: 500;
      margin: 0.5rem 0;
      color: #24c586;
    }
    .warn {
      color: #e84a4a;
    }

    form {
      background-color: $formp_l;
      box-shadow: 0 15px 30px rgb(85 85 85 / 40%);
      padding: 2rem;
      border-radius: 1rem;
      position: relative;


      .cheks {
        flex-wrap: wrap;
        label {
          cursor: pointer;
          margin-right: 1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: $gray;
        }
        input {
          margin-right: 1rem;
          cursor: pointer;
        }
      }

      .twoinput,
      .threeinput {
        width: 100%;
        align-items: flex-start;
        .inbox {
          position: relative;
          width: 49%;
          input{
            width: 100%;
          }
        }
      }

      .threeinput {
        .inbox {
          position: relative;
          width: calc((100% / 3) - 5px);
          input{
            width: 100%;
          }
        }
      }

      input {
        width: 100%;
        background-color: $light;
        color: $gray;
        font-size: 14px;
        padding: 0.8rem;
        border: none;
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        outline: none;
        &::placeholder {
          color: $l_ph;
        }
      }
      .sbox {
        padding-right: 1rem;
        background-color: $light;
        border-radius: 0.5rem;
        width: 100%;
      }

      .sc {
        width: 100%;
        .sbox {
          width: 49%;
        }
        input {
          width: 49%;
        }
      }

      .dragBoxes , .dragBoxes2 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;

        h5 {
          font-size: 16px;
          margin-top: 1.5rem;
          font-weight: 500;
          color: $gray;
        }

        .dragbox {
          position: relative;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 97px;
          border-radius: 0.5rem;
          border: 1px solid #9c9c9c4a;
          margin: 0rem 0;
        }
      }

      .dragBoxes2{
        grid-template-columns: repeat(2 , 1fr);
      }
    }

    .bankdetials_btn {
      margin-top: 1rem;
      border-radius: 2rem;
    }
  }



}

// ******* Dark mode ********

.bankdetials_d {
  background-image: url("../../../Assets/hero_dark_bg.png");
  nav {
    background-color: $register_nav;
  }
  ._bankdetials {
    form {
      background-color: rgb(24 23 34 / 99%);
      box-shadow: none;
      label {
        color: $light !important;
      }
      h1,
      .terms {
        color: $light;
      }
      h4,
      h5 {
        color: $third !important;
      }
    }
  }
}

// ****** Responsive ********

@media screen and (max-width: 900px) {
  .bankdetials_d,
  .bankdetials_l {
    .bgi {
      width: 90%;
    }
    .dragBoxes , .dragBoxes2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .bankdetials_d,
  .bankdetials_l {
    form {
      padding: 1rem !important;
      .twoinput,
      .threeinput {
        flex-direction: column;
        .inbox{
          width: 100% !important;
          input {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .bankdetials_d,
  .bankdetials_l {
    .dragBoxes , .dragBoxes2 {
      grid-template-columns: 1fr !important;
    }
  }
}
